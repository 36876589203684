/* eslint-disable */

import moment from 'moment-timezone';
import * as R from 'ramda';
import { switchProp, determinePhotoStatus } from 'utilsModule';

/* Login */
const getServerMapping = {
  fe2be: R.identity,
  be2fe: ({ value: { api_url, gothumb_url, login_url, status, error } }) => ({
    API_URL: api_url,
    GOTHUMB_URL: gothumb_url,
    LOGIN_URL: login_url,
    status,
    error
  }),
};

const request2FA = {
  fe2be: R.identity,
  be2fe: ({ value: { token, otpVia } = {} }) =>
    // BE: Need to return proper mobile obj
    ({ token, otpVia }),
};

const verify2FA = {
  fe2be: R.identity,
  be2fe: ({ value }) => value,
};

const selectProfile = {
  fe2be: R.identity,
  be2fe: ({ value }) => value,
};

const switchProfile = {
  fe2be: R.identity,
  be2fe: ({ value }) => value,
};

//forgetpassword
const forgetPassword = {
  fe2be: R.identity,
  be2fe: ({ value }) => value,
};

//password validation
const passwordVerifyOTP = {
  fe2be: R.identity,
  be2fe: ({ value }) => value,
};

//resetpassword
const resetPassword = {
  fe2be: R.identity,
  be2fe: ({ value }) => value,
};

//logout 
const logoutAccount = {
  fe2be: R.identity,
  be2fe: ({ value }) => value,
}

// Revoke Session 
const revokeSession = {
  fe2be: R.identity,
  be2fe: ({ value }) => value,
}

/* Onboard */
const requestInvitationCodeForMobile = {
  fe2be: R.identity,
  be2fe: ({
    value: {
      downloadInfoForiOS: { uri: appStoreUri = '' } = {},
      downloadInfoForAndroid: { uri: playStoreUri = '' } = {},
    } = {},
  }) => ({ appStoreUri, playStoreUri }),
};

// Opt out the onboard notification
const reqOptOutOnboardNotification = {
  fe2be: R.identity,
  be2fe: ({ value }) => value,
};

const requestInvitationToken = {
  fe2be: R.identity,
  // BE: To return phone: '65 91234567'
  be2fe: ({ value: { invitationToken: token, phone: value, countryCode } = {} }) => ({
    token,
    phone: { value, countryCode },
  }),
};

const requestInvitationCode = {
  fe2be: R.identity,
  be2fe: R.identity,
};

const verifyInvitationCode = {
  fe2be: R.identity,
  be2fe: ({ value: { nextAction, username } = {} }) => ({ nextAction }),
};

const registerAuth = {
  fe2be: R.identity,
  be2fe: ({ value: { otpToken: token, phone: value } = {} }) => ({ token, phone: { countryCode: '65', value } }),
};

const request2FAForOnboard = {
  fe2be: R.identity,
  be2fe: ({ value: { otpToken: token, phone: value } = {} }) => ({ token, phone: { countryCode: '65', value } }),
};

const verify2FAForOnboard = {
  fe2be: R.identity,
  be2fe: ({ value }) => value,
};

const updateDemographic = {
  fe2be: ({
    idType: { value: idType },
    idNo,
    firstName,
    lastName,
    dob,
    gender: { value: gender },
    nationality: { value: nationality },
  }) => ({
    demographics: {
      name: { firstName, lastName },
      identification: { type: idType, value: idNo },
      gender,
      birthDate: moment(dob).format('YYYY-MM-DD'),
      nationality,
    },
  }),
  be2fe: ({ value }) => value,
};

/* Users */
const patient = {
  be2fe: ({
    currentDemographics: {
      profile_photo = '',
      name: { firstName = '', lastName = '' } = {},
      birthDate: dob,
      address: { line: addressLine = '', ...addressProps } = {},
      ...otherDemographics
    },
    ...rest
  }) => ({
    identity: {
      avatar: profile_photo,
      firstName,
      lastName,
    },
    dob,
    age: moment().diff(dob, 'years'),
    address: {
      ...addressProps,
      line1: R.pipe(
        R.split(', '),
        R.propOr('', 0),
      )(addressLine || ''),
      line2: R.pipe(
        R.split(', '),
        R.propOr('', 1),
      )(addressLine || ''),
    },
    ...otherDemographics,
    ...rest,
  }),
};

const patientCovid = {
  be2fe: ({
    // eslint-disable-next-line camelcase
    profile_photo = '',
    name: { firstName = '', lastName = '' } = {},
    birthDate: dob,
    enrollPrograms = [],
    ...rest
  }) => ({
    identity: {
      avatar: profile_photo,
      firstName,
      lastName,
    },
    dob,
    age: moment().diff(dob, 'years'),
    enrollPrograms: R.map(({ title, status, period: { start, end } = {}, duration: { calendar, length } = {} }) => ({
      title,
      status,
      duration: `${length} ${R.toLower(calendar)}(s)`,
      startDate: moment.parseZone(start).format('YYYY-MM-DD'),
      endDate: moment.parseZone(end).format('YYYY-MM-DD'),
      expiringIn: moment(end).diff(moment(), 'days'),
    }))(enrollPrograms),
    ...rest,
  }),
};

const create = {
  fe2be: ({
    accountType: type,
    basicInfo: {
      idType: { value: idType } = {},
      idNo,
      firstName,
      lastName,
      dob,
      gender: { value: gender } = {},
      height,
      weight,
      nationality: { value: nationality } = {},
      race: { value: race } = {},
      language: { value: language } = {},
      languagePreference: { value: languagePreference } = {},
      mobile: providerMobile,
    } = {},
    avatar,
    /* PATIENT */
    contactDetails: {
      email,
      mobile: patientMobile,
      home: patientHome,
      addressLine1,
      addressLine2 = '',
      postalCode,
      country: { value: country } = {},
      state: { value: state } = {},
      city: { value: city } = {},
    } = {},
    // TODO: BE needs to discuss with BA
    medicalDetails: { programCondition } = {},
    /* PROVIDER */
    placements = [],
  }) => ({
    user: {
      identification: {
        value: idNo,
        type: idType,
      },
      ...(type === 'PATIENT'
        ? {
            mobile: R.evolve({ countryCode: R.prop('value') })(patientMobile),
            race,
            language,
            languagePreference,
            height: {
              height: height === '' ? null : height,
            },
            weight: {
              weight: weight === '' ? null : weight,
            },
            address: {
              line: R.join(', ', [addressLine1, addressLine2]),
              city,
              district: '',
              state,
              postalCode,
              country,
            },
          }
        : {
            mobile: providerMobile,
          }),
      name: {
        firstName,
        lastName,
      },
      ...(avatar
        ? {
            photo: {
              file_suffix: '.jpeg',
              data: R.replace('data:image/jpeg;base64,', '', avatar),
            },
          }
        : {}),
      gender,
      birthDate: moment(dob).isValid() ? moment(dob).format('YYYY-MM-DD') : null,
      nationality,
    },
    // TODO: FE should be agnostic of BE, no coupling
    ...(type === 'PATIENT'
      ? {
          placement: [{ type, email, home_office: R.evolve({ countryCode: R.prop('value') })(patientHome) }],
        }
      : {
          placement: R.map(
            ({
              institution: { options: hcList, value: hcValue } = {},
              department: { options: departmentList, value: departmentValue } = {},
              specialty: { value: specialty } = {},
              prn: PRN,
              email,
              role: { value: type } = {},
              office: home_office,
            }) => ({
              // NOTE: User is either placed under HOSPITAL_CLINIC or DEPARTMENT (if is selected), not both
              orgId: switchProp('id', 'value', departmentValue || hcValue, departmentValue ? departmentList : hcList),
              PRN,
              type,
              email,
              home_office,
              // BE: typo speciality --> specialty
              speciality: specialty,
            }),
          )(placements),
        }),
  }),
  be2fe: R.identity,
};

const createCovid = {
  fe2be: ({
    basicInfo: {
      email,
      mobile,
      firstName,
      lastName,
      dob,
      gender: { value: gender } = {},
      homeNumber,
      addr1: line_1,
      addr2: line_2,
      postalCode,
      country,
      state: { value: state = null } = {},
      city: { value: city },
    },
    paymentDetails: { creditCardToken },
  }) => {
    return {
      placement: [
        {
          email,
          home_office: {
            value: homeNumber.value.value,
            countryCode: homeNumber.countryCode,
          },
          type: 'PATIENT',
        },
      ],
      user: {
        mobile: {
          value: mobile.value.value,
          countryCode: mobile.countryCode,
        },
        address: {
          line: R.join(', ', [line_1, line_2]),
          city,
          district: '',
          state,
          postalCode,
          country: R.prop('value', country),
        },
        gender,
        birthDate: moment(dob).isValid() ? moment(dob).format('YYYY-MM-DD') : null,
        height: {},
        weight: {},
        name: {
          firstName,
          lastName,
        },
        nationality: '', // this is mandatory in BE
        identification: {
          // this is mandatory in BE
          value: '',
          type: '',
        },
        race: '',
        creditCardToken,
      },
    };
  },
  be2fe: R.identity,
};

const updatePatient = {
  fe2be: ({
    accountType: type,
    patientId,
    basicInfo: {
      idType: { value: idType } = {},
      idNo,
      firstName,
      lastName,
      dob,
      gender: { value: gender } = {},
      height,
      weight,
      nationality: { value: nationality } = {},
      race: { value: race } = {},
      language: { value: language } = {},
      languagePreference: { value: languagePreference } = {},
      mobile: providerMobile,
    } = {},
    avatar,
    /* PATIENT */
    contactDetails: {
      email,
      mobile: patientMobile,
      home: patientHome,
      addressLine1,
      addressLine2 = '',
      postalCode,
      country: { value: country } = {},
      state: { value: state } = {},
      city: { value: city } = {},
    } = {},
    // TODO: BE needs to discuss with BA
    medicalDetails: { programCondition } = {},
    /* PROVIDER */
    placements = [],
  }) => ({
    demographics: {
      identification: {
        value: idNo,
        type: idType,
      },
      ...(type === 'PATIENT'
        ? {
            mobile: R.evolve({ countryCode: R.prop('value') })(patientMobile),
            race,
            language,
            languagePreference,
            height: {
              height: height === '' ? null : height,
            },
            weight: {
              weight: weight === '' ? null : weight,
            },
            address: {
              line: R.join(', ', [addressLine1, addressLine2]),
              city,
              district: '',
              state,
              postalCode,
              country,
            },
          }
        : {
            mobile: providerMobile,
          }),
      name: {
        firstName,
        lastName,
      },
      ...(avatar
        ? {
            photo: {
              photoStatus: "RETAIN",
              file_suffix: '.jpeg',
              data: R.replace('data:image/jpeg;base64,', '', avatar),
            },
          }
        : {}),
      gender,
      birthDate: moment(dob).isValid() ? moment(dob).format('YYYY-MM-DD') : null,
      nationality,
    },
    // TODO: FE should be agnostic of BE, no coupling
    ...(type === 'PATIENT'
      ? {
          placement: [
            { type, id: patientId, email, home_office: R.evolve({ countryCode: R.prop('value') })(patientHome) },
          ],
        }
      : {
          placement: R.map(
            ({
              institution: { options: hcList, value: hcValue } = {},
              department: { options: departmentList, value: departmentValue } = {},
              specialty: { value: specialty } = {},
              prn: PRN,
              email,
              role: { value: type } = {},
              office: home_office,
            }) => ({
              // NOTE: User is either placed under HOSPITAL_CLINIC or DEPARTMENT (if is selected), not both
              orgId: switchProp('id', 'value', departmentValue || hcValue, departmentValue ? departmentList : hcList),
              PRN,
              type,
              email,
              home_office,
              // BE: typo speciality --> specialty
              speciality: specialty,
            }),
          )(placements),
        }),
  }),
  be2fe: ({
    value: {
      demographics: {
        profile_photo = '',
        name: { firstName = '', lastName = '' } = {},
        birthDate: dob,
        address: { line: addressLine = '', ...addressProps } = {},
        ...rest
      },
      profiles = [],
    } = {},
  }) => ({
    patient: {
      ...rest,
      id: R.path([0, 'id'], profiles),
      identity: {
        avatar: profile_photo,
        firstName,
        lastName,
      },
      dob,
      age: moment().diff(dob, 'years'),
      address: {
        ...addressProps,
        line1: R.pipe(
          R.split(', '),
          R.propOr('', 0),
        )(addressLine || ''),
        line2: R.pipe(
          R.split(', '),
          R.propOr('', 1),
        )(addressLine || ''),
      },
      email: R.path([0, 'email'], profiles),
      onboarded: R.path([0, 'onboard'], profiles),
      home_office: R.path([0, 'home_office'], profiles),
    },
  }),
};

const updateProvider = {
  fe2be: ({
    accountType: type,
    patientId,
    basicInfo: {
      idType: { value: idType } = {},
      idNo,
      firstName,
      lastName,
      dob,
      gender: { value: gender } = {},
      height,
      weight,
      nationality: { value: nationality } = {},
      race: { value: race } = {},
      language: { value: language } = {},
      mobile: providerMobile,
    } = {},
    avatar,
    /* PATIENT */
    contactDetails: {
      email,
      mobile: patientMobile,
      home: patientHome,
      addressLine1,
      addressLine2 = '',
      postalCode,
      country: { value: country } = {},
      city: { value: city } = {},
    } = {},
    // TODO: BE needs to discuss with BA
    medicalDetails: { programCondition } = {},
    /* PROVIDER */
    placements = [],
  }) => {
    return {
      demographics: {
        identification: {
          value: idNo,
          type: idType,
        },
        ...(type === 'PATIENT'
          ? {
              mobile: R.evolve({ countryCode: R.prop('value') })(patientMobile),
              race,
              language,
              height: {
                height,
              },
              weight: {
                weight,
              },
              address: {
                line: R.join(', ', [addressLine1, addressLine2]),
                city,
                district: '',
                state: '',
                postalCode,
                country,
              },
            }
          : {
              mobile: providerMobile,
            }),
        name: {
          firstName,
          lastName,
        },
        gender,
        birthDate: moment(dob).isValid() ? moment(dob).format('YYYY-MM-DD') : null,
        nationality,
      },
      avatar: {
        photo: determinePhotoStatus(avatar)
      },
      // TODO: FE should be agnostic of BE, no coupling
      ...(type === 'PATIENT'
        ? {
            placement: [
              { type, id: patientId, email, home_office: R.evolve({ countryCode: R.prop('value') })(patientHome) },
            ],
          }
        : {
            placement: R.map(
              ({
                id,
                institution: { options: hcList, value: hcValue } = {},
                department: { options: departmentList, value: departmentValue } = {},
                specialty: { value: specialty } = {},
                prn: PRN,
                email,
                role: { value: type } = {},
                office: home_office,
              }) => ({
                // NOTE: User is either placed under HOSPITAL_CLINIC or DEPARTMENT (if is selected), not both
                orgId: switchProp('id', 'value', departmentValue || hcValue, departmentValue ? departmentList : hcList),
                id,
                PRN,
                type,
                email,
                home_office,
                // BE: typo speciality --> specialty
                speciality: specialty,
              }),
            )(placements),
          }),
    };
  },
  be2fe: ({
    value: {
      demographics: { profile_photo = '', name: { firstName = '', lastName = '' } = {}, birthDate: dob, ...rest },
      profiles,
    } = {},
  }) => ({
    provider: {
      ...rest,
      identity: {
        avatar: profile_photo,
        firstName,
        lastName,
      },
      dob,
      age: moment().diff(dob, 'years'),
      placements: R.map(
        ({
          organization: { id: orgId, name: orgName, type: orgType } = {},
          specialty: { name: specialtyName } = {},
          ...others
        }) => ({
          hospital: {},
          department: {},
          [orgType === 'HOSPITAL_CLINIC' ? 'hospital' : 'department']: { id: orgId, label: orgName },
          specialty: { value: specialtyName, label: specialtyName },
          ...others,
        }),
      )(profiles),
    },
  }),
};

const updateProfile = {
  fe2be: ({
    accountType: type,
    patientId,
    basicInfo: {
      idType: { value: idType } = {},
      idNo,
      firstName,
      lastName,
      dob,
      gender: { value: gender } = {},
      height,
      weight,
      nationality: { value: nationality } = {},
      race: { value: race } = {},
      language: { value: language } = {},
      mobile: providerMobile,
    } = {},
    avatar,
    /* PATIENT */
    contactDetails: {
      email,
      mobile: patientMobile,
      home: patientHome,
      addressLine1,
      addressLine2 = '',
      postalCode,
      country: { value: country } = {},
      city: { value: city } = {},
    } = {},
    // TODO: BE needs to discuss with BA
    medicalDetails: { programCondition } = {},
    /* PROVIDER */
    placements = [],
  }) => {
    return {
      demographics: {
        identification: {
          value: idNo,
          type: idType,
        },
        ...(type === 'PATIENT'
          ? {
              mobile: R.evolve({ countryCode: R.prop('value') })(patientMobile),
              race,
              language,
              height: {
                height,
              },
              weight: {
                weight,
              },
              address: {
                line: R.join(', ', [addressLine1, addressLine2]),
                city,
                district: '',
                state: '',
                postalCode,
                country,
              },
            }
          : {
              mobile: providerMobile,
            }),
        name: {
          firstName,
          lastName,
        },
        gender,
        birthDate: moment(dob).isValid() ? moment(dob).format('YYYY-MM-DD') : null,
        nationality,
      },
      avatar: {
        photo: determinePhotoStatus(avatar)
      },
      // TODO: FE should be agnostic of BE, no coupling
      ...(type === 'PATIENT'
        ? {
            placement: [
              { type, id: patientId, email, home_office: R.evolve({ countryCode: R.prop('value') })(patientHome) },
            ],
          }
        : {
            placement: R.map(
              ({
                id,
                institution: { options: hcList, value: hcValue } = {},
                department: { options: departmentList, value: departmentValue } = {},
                specialty: { value: specialty } = {},
                prn: PRN,
                email,
                role: { value: type } = {},
                office: home_office,
              }) => ({
                // NOTE: User is either placed under HOSPITAL_CLINIC or DEPARTMENT (if is selected), not both
                orgId: switchProp('id', 'value', departmentValue || hcValue, departmentValue ? departmentList : hcList),
                id,
                PRN,
                type,
                email,
                home_office,
                // BE: typo speciality --> specialty
                speciality: specialty,
              }),
            )(placements),
          }),
    };
  },
  be2fe: ({
    value: {
      demographics: { profile_photo = '', name: { firstName = '', lastName = '' } = {}, birthDate: dob, ...rest },
      profiles,
    } = {},
  }) => ({
    provider: {
      ...rest,
      identity: {
        avatar: profile_photo,
        firstName,
        lastName,
      },
      dob,
      age: moment().diff(dob, 'years'),
      placements: R.map(
        ({
          organization: { id: orgId, name: orgName, type: orgType } = {},
          specialty: { name: specialtyName } = {},
          ...others
        }) => ({
          hospital: {},
          department: {},
          [orgType === 'HOSPITAL_CLINIC' ? 'hospital' : 'department']: { id: orgId, label: orgName },
          specialty: { value: specialtyName, label: specialtyName },
          ...others,
        }),
      )(profiles),
    },
  }),
};

const retrieveCurrent = {
  fe2be: R.identity,
  be2fe: ({ value }) => R.omit(['sessionId'], value),
};

const retrieveMany = {
  fe2be: R.identity,
  be2fe: R.identity,
};

const retrieveProviders = {
  fe2be: R.identity,
  be2fe: ({ value: { providers, pagination: { page_no, page_size, total } = {} } = {} }) => ({
    list: R.map(({ profile_photo = '', name: { firstName = '', lastName = '' } = {}, profiles, ...rest }) => ({
      identity: {
        avatar: profile_photo,
        firstName,
        lastName,
      },
      placements: R.map(
        ({
          organization: { id: orgId, name: orgName, type: orgType } = {},
          specialty: { name: specialtyName } = {},
          ...others
        }) => ({
          hospital: {},
          department: {},
          [orgType === 'HOSPITAL_CLINIC' ? 'hospital' : 'department']: { id: orgId, label: orgName },
          specialty: { value: specialtyName, label: specialtyName },
          ...others,
        }),
      )(profiles),
      ...rest,
    }))(providers),
    options: {
      pagination: {
        // BE: BE should pass page_no & page_size as number
        currentPage: parseInt(page_no) - 1,
        pageSize: parseInt(page_size),
        totalCount: total,
      },
    },
  }),
};

const retrieveProvider = {
  fe2be: R.identity,
  be2fe: ({
    value: { profile_photo = '', name: { firstName = '', lastName = '' } = {}, birthDate: dob, profiles, ...rest },
  }) => ({
    provider: {
      ...rest,
      identity: {
        avatar: profile_photo,
        firstName,
        lastName,
      },
      dob,
      age: moment().diff(dob, 'years'),
      placements: R.map(
        ({
          organization: { id: orgId, name: orgName, type: orgType } = {},
          specialty: { name: specialtyName } = {},
          ...others
        }) => ({
          hospital: {},
          department: {},
          [orgType === 'HOSPITAL_CLINIC' ? 'hospital' : 'department']: { id: orgId, label: orgName },
          specialty: { value: specialtyName, label: specialtyName },
          ...others,
        }),
      )(profiles),
    },
  }),
};

const retrievePatients = {
  fe2be: R.identity,
  be2fe: ({ value: { patients, pagination: { page, pageSize, total } = {} } = {} }) => ({
    list: R.map(patient.be2fe)(patients),
    options: {
      pagination: {
        // BE: BE should pass page_no & page_size as number
        currentPage: parseInt(page) - 1,
        pageSize: parseInt(pageSize),
        totalCount: total,
      },
    },
  }),
};

const retrieveSortingFilter = {
  fe2be: R.identity,
  be2fe: ({ value }) => value,
};

const resetSortingFilter = {
  fe2be: R.identity,
  be2fe: ({ value }) => value,
};

const retrievePatient = {
  fe2be: R.identity,
  be2fe: R.pipe(
    R.path(['value', 'patient']),
    patient.be2fe,
    R.assoc('patient', R.__, {}),
  ),
};

const retrievePatientProfiles = {
  fe2be: R.identity,
  be2fe: ({ value }) => ({ value }),
};

const retrievePatientsCovid = {
  fe2be: R.identity,
  be2fe: ({ value: { patientsCovid, pagination: { page, pageSize, total } = {} } = {} }) => ({
    list: R.map(patientCovid.be2fe)(patientsCovid),
    pagination: {
      currentPage: parseInt(page) - 1,
      pageSize: parseInt(pageSize),
      totalCount: total,
    },
  }),
};

const discharge = {
  fe2be: ({ patientCovidId }) => ({ patientCovidId }),
  be2fe: R.identity,
};

const maintenanceSchedule = {
  fe2be: R.identity,
  be2fe: ({ value }) => value,
};

const getNotificationSettings = {
  fe2be: R.identity,
  be2fe: ({ value }) => value,
};

const changeNotificationSettings = {
  fe2be: R.identity,
  be2fe: ({ value }) => value,
};

const updateACL = {
  fe2be: R.identity,
  be2fe: ({ value }) => value,
};

const retrieveDefaultAcl = {
  fe2be: R.identity,
  be2fe: ({ value }) => value,
};

const resendAccountActivation = {
  fe2be: R.identity,
  be2fe: ({ value }) => value,
};

const retrieveNotifications = {
  fe2be: R.identity,
  be2fe: ({ value }) => value,
};

const inactivateNotifications = {
  fe2be: R.identity,
  be2fe: ({ value }) => value,
};

const getPollingInfo = {
  fe2be: R.identity,
  be2fe: ({ value }) => ({ value }),
};

export default {
  getServerMapping,
  request2FA,
  verify2FA,
  selectProfile,
  switchProfile,
  requestInvitationCodeForMobile,
  requestInvitationToken,
  requestInvitationCode,
  verifyInvitationCode,
  registerAuth,
  request2FAForOnboard,
  verify2FAForOnboard,
  updateDemographic,
  create,
  updatePatient,
  updateProvider,
  updateProfile,
  retrieveCurrent,
  retrieveMany,
  retrieveProviders,
  retrieveProvider,
  retrievePatients,
  retrievePatient,
  retrievePatientsCovid,
  discharge,
  forgetPassword,
  resetPassword,
  logoutAccount,
  revokeSession,
  maintenanceSchedule,
  createCovid,
  passwordVerifyOTP,
  getNotificationSettings,
  changeNotificationSettings,
  updateACL,
  retrieveDefaultAcl,
  resendAccountActivation,
  retrieveNotifications,
  inactivateNotifications,
  retrieveSortingFilter,
  resetSortingFilter,
  retrievePatientProfiles,
  reqOptOutOnboardNotification,
  getPollingInfo
};
