/* eslint-disable class-methods-use-this */
/* eslint-disable object-curly-newline */
/* eslint-disable max-len */
/* eslint-disable no-console */
import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import ReactHoverObserver from 'react-hover-observer';
import SwipeableViews from 'hb-react-swipeable-views-new';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { FormSection, Field, Fields, FieldArray, reduxForm } from 'redux-form';
import {
  Grid,
  Paper,
  Typography,
  FormLabel,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  Input,
  InputLabel,
  InputAdornment,
  Divider,
  Button,
  IconButton,
  Badge,
  Tooltip,
  Select as MuiSelect,
  Switch as MuiSwitch,
  Radio,
  RadioGroup as MuiRadioGroup,
  Checkbox as MuiCheckbox,
  MenuItem,
  AppBar,
  Tabs as MuiTabs,
  Tab as MuiTab,
  Stepper as MuiStepper,
  Step,
  StepButton,
  Modal,
  Fab,
  Avatar as MuiAvatar,
  Box,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { createMuiTheme, ThemeProvider, makeStyles } from '@material-ui/core/styles';
import {
  Event,
  EventAvailable,
  AddAlarm,
  AlarmOn,
  AddPhotoAlternate,
  Close,
  Clear,
  Delete,
  DeleteForever,
  ModeEdit,
  CheckCircle,
  Error,
  Save,
  InsertPhoto,
  ArrowDropUp,
  ArrowDropDown,
  RotateLeft,
  RotateRight,
  ZoomIn,
  ZoomOut,
  Visibility,
  VisibilityOff,
  Favorite,
  InfoOutline,
} from 'utilsModule/material-ui-icons';
import IconCalendar from 'dataModule/assets/img/IconCalendar.svg';
import {
  DatePicker as MuiDatePicker,
  TimePicker as MuiTimePicker,
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import MomentUtils from '@date-io/moment';
import { createSliderWithTooltip, Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import ReactVirtualizedSelect from 'react-virtualized-select';
import AvatarEditor from 'react-avatar-editor';
import classNames from 'classnames';
import update from 'immutability-helper';
import uuidv4 from 'uuid/v4';
import moment from 'moment-timezone';
import debounce from 'lodash/debounce';
import * as R from 'ramda';
import FontFaceObserver from 'fontfaceobserver';
import { memoize, switchProp, findIndexBy, joinSpecificValue } from 'utilsModule';
import commonUtil from 'utilsModule/common';
import { Loading, ImageAvatar } from 'utilsModule/components';
import { defaultMeta, reduceMetas, decideStatusFromMeta, StatusIndicator, normalize } from '.';
import { roundFloatToFixedDecimal } from 'utilsModule/common';
import './index.scss';
import DialogProgramValidation from 'appModule/program/components/DialogProgramValidation';

const styles = (theme) => ({
  status: {
    pristine: {
      color: '#00b3b3',
    },
    dirty: {
      color: '#FFC107',
    },
    touchedValid: {
      color: '#81C784',
    },
    visiblyInvalid: {
      color: 'lightcoral',
    },
  },
  underline: {
    borderBottom: '2px solid white',
    '&:after': {
      // The MUI source seems to use this but it doesn't work
      borderBottom: '2px solid white',
    },
  },
  root: {
    underline: {
      '&:before': {
        backgroundColor: '#e0e0e0',
        left: 0,
        bottom: 0,
        // Doing the other way around crash on IE11 "''" https://github.com/cssinjs/jss/issues/242
        content: '""',
        height: 1,
        position: 'absolute',
        right: 0,
        transition: theme.transitions.create('background-color', {
          duration: theme.transitions.duration.shorter,
        }),
        pointerEvents: 'none', // Transparent to the hover style.
      },
      '&:hover:not($disabled):before': {
        backgroundColor: '#9e9e9e',
        height: '2px',
      },
      '&$disabled:before': {
        background: 'transparent',
        backgroundImage: 'linear-gradient(to right, #e0e0e0 33%, transparent 0%)',
        backgroundPosition: 'left top',
        backgroundRepeat: 'repeat-x',
        backgroundSize: '5px 1px',
      },
      '&:hover$error:before': {
        backgroundColor: theme.palette.error.main,
        transform: 'scaleX(1)', // error is always underlined in red
      },
      '&$error:before': {
        backgroundColor: theme.palette.error.main,
        transform: 'scaleX(1)', // error is always underlined in red
      },
    },
    root: {
      '&:after': {
        backgroundColor: '#414EC1',
        left: 0,
        bottom: 0,
        // Doing the other way around crash on IE11 "''" https://github.com/cssinjs/jss/issues/242
        content: '""',
        height: 2,
        position: 'absolute',
        right: 0,
        transform: 'scaleX(0)',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shorter,
          easing: theme.transitions.easing.easeOut,
        }),
        pointerEvents: 'none', // Transparent to the hover style.
      },
      '&$focused:after': {
        transform: 'scaleX(1)',
      },
      // Increase specificity
      '&$error:after': {
        backgroundColor: theme.palette.error.main,
        transform: 'scaleX(1)', // error is always underlined in red
      },
    },
    focused: {},
    error: {},
    disabled: {},
  },
});

const InfoTooltip = withStyles(() => ({
  tooltip: {
    background: '#212121 0% 0% no-repeat padding-box',
    boxShadow: '0px 4px 16px #00000052',
    borderRadius: '8px',
    minWidth: 280,
    maxWidth: 545,
    color: 'white',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    padding: 24,
  },
}))(Tooltip);

const defaultMaterialTheme = createMuiTheme({
  overrides: {
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: '1px solid #e0e0e0',
        },
        '&:hover:not(.Mui-disabled):before': {
          borderBottom: '2px solid rgb(144 144 144 / 87%) !important',
        },
      },
      input: {
        '&::placeholder': {
          letterSpacing: '0.08px',
          fontStyle: 'normal',
        },
      },
    },
  },
});

const usePlaceholderStyles = makeStyles((theme) => ({
  placeholder: {
    color: '#aaa',
    fontWeight: '400',
  },
}));

const Placeholder = ({ children }) => {
  const classes = usePlaceholderStyles();
  return <div className={classes.placeholder}>{children}</div>;
};

class Section extends React.PureComponent {
  static propTypes = {
    name: PropTypes.string,
    component: PropTypes.any,
    rows: PropTypes.array.isRequired,
    itemProps: PropTypes.shape({
      overall: PropTypes.object,
      body: PropTypes.object,
    }),
    containerProps: PropTypes.shape({
      overall: PropTypes.object,
      body: PropTypes.object,
    }),
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
  };

  defaultComponent = ({
    title,
    typography = { variant: 'h5' },
    children,
    prefixAvatar = null,
    prefixAvatarStatus = null,
  }) => {
    const {
      itemProps: { body: sectionBodyItemProps = {} } = {},
      containerProps: { overall: sectionOverallContainerProps = {}, header: sectionHeaderProps = {} } = {},
    } = this.props;

    const avatarChoices = {
      pristine: {
        style: { backgroundColor: '#212121', color: '#FFFFFF' },
      },
      dirty: {
        style: { backgroundColor: '#212121', fontSize: '16px' },
      },
      touchedValid: {
        style: { backgroundColor: '#212121', fontSize: '16px' },
      },
      visiblyInvalid: {
        style: { backgroundColor: '#212121', fontSize: '16px' },
      },
    };

    return (
      <Grid container {...sectionOverallContainerProps}>
        {title && (
          <Grid item xs={12} style={{ padding: '0px' }}>
            <Grid item xs={12} style={sectionHeaderProps.style ? sectionHeaderProps.style : {}}>
              <div
                style={{
                  display: 'flex',
                  gap: '16px',
                  alignItems: 'center',
                }}
              >
                {prefixAvatar && prefixAvatarStatus && (
                  <MuiAvatar aria-label={title} style={avatarChoices[prefixAvatarStatus].style}>
                    {prefixAvatar}
                  </MuiAvatar>
                )}
                <Typography {...typography}>{title}</Typography>
              </div>
            </Grid>
            {/* <div
              style={{
                backgroundColor: '#212121',
                // width: '100%',
                fontSize: '16px',
                display: 'flex',
                alignItems: 'center',
                padding: '20px 24px',
                margin: '0px -8px'
              }}
            >
              <Typography variant="body1" style={{color: 'white'}}>
                Organisation Information descriptions
              </Typography>
            </div> */}
          </Grid>
        )}
        <Grid item xs={12} {...sectionBodyItemProps}>
          {children}
        </Grid>
      </Grid>
    );
  };

  constructor(props) {
    super(props);
    const { rows } = props;
    const metas = {};
    const components = R.flatten(R.map(({ cols }) => cols, rows));

    R.pipe(
      R.filter(({ ignoreMeta }) => !ignoreMeta),
      R.forEach(({ name }) => {
        metas[name] = R.clone(defaultMeta);
      }),
    )(components);

    this.state = { metas };
  }

  changeSectionMeta = memoize((componentName) => (meta) =>
    this.setState(({ metas: prevMetas }, { onMetaChange: onSectionMetaChange }) => {
      const nextMetas = update(prevMetas, { [componentName]: { $set: meta } });
      const sectionMeta = reduceMetas(nextMetas);
      onSectionMetaChange && onSectionMetaChange(sectionMeta);
      return { metas: nextMetas };
    }),
  );

  renderLayout({
    rows,
    containerProps: { body: sectionBodyContainerProps = {} } = {},
    disabled: sectionDisabled,
    readonly: sectionReadonly,
    ...rest
  }) {
    return (
      <Grid container {...sectionBodyContainerProps}>
        {rows.map(({ id: rowKey, containerProps: rowContainerProps, cols }) => (
          <Grid key={rowKey} item xs={12}>
            <Grid container justify="center" spacing={2} {...rowContainerProps}>
              {cols.map(({ id: colKey, name, component: Component, colSpans, inject, ignoreMeta, ...props }) => (
                <Grid key={colKey || name} item xs {...colSpans}>
                  <Component
                    name={name}
                    disabled={!!sectionDisabled}
                    readonly={!!sectionReadonly}
                    {...props}
                    {...(inject ? inject(rest) : {})}
                    // NOTE: onMetaChange should be destructured above or put as last here since this component can receive onMetaChange
                    {...(ignoreMeta ? {} : { onMetaChange: this.changeSectionMeta(name) })}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        ))}
      </Grid>
    );
  }

  render() {
    const { metas } = this.state;
    const {
      name,
      component: Component = this.defaultComponent,
      itemProps: { overall: sectionOverallItemProps = {} } = {},
      style,
      displayCondition = null,
      ...rest
    } = this.props;

    // console.log('%crender Section', 'font-size: 12px; color: #00b3b3', this.state, this.props);

    /*
      displayCondition
      - is a function to determine if a section needs to be rendered / displayed
      - is an optional field in a Form Section
      - is useful when there is a section that needs to be hidden based on a condition(s)
      - The condition's prop needs to be passed into the <Form> component for it to be accessible here
    */
    if (displayCondition && !displayCondition(this.props)) {
      return null;
    }

    return ((content) => (name ? <FormSection name={name}>{content}</FormSection> : content))(
      <Grid item xs={12} {...sectionOverallItemProps} style={{ marginTop: 20, ...style }}>
        <Component {...rest} meta={reduceMetas(metas)}>
          {this.renderLayout(rest)}
        </Component>
      </Grid>,
    );
  }
}

const withOnMetaChange = (subMeta = ['pristine', 'dirty', 'touchedValid', 'visiblyInvalid']) => (RawField) => {
  class WithOnMetaChange extends React.PureComponent {
    addTouchedValid = (meta) => ({ ...meta, touchedValid: meta.touched && meta.valid });

    addVisiblyInvalid = (meta) => ({ ...meta, visiblyInvalid: meta.touched && meta.invalid });

    getExtraMeta = R.pipe(this.addTouchedValid, this.addVisiblyInvalid);

    getReportedMeta = R.pipe(this.getExtraMeta, R.pick(subMeta));

    reportMetaChange = (prevMeta, meta) => {
      const { onMetaChange, ignoreMeta } = this.props;

      if (ignoreMeta) return;

      const reportedMeta = this.getReportedMeta(meta);
      const prevReportedMeta = this.getReportedMeta(prevMeta);

      !R.equals(reportedMeta, prevReportedMeta) && onMetaChange && onMetaChange(reportedMeta);
    };

    componentDidMount() {
      this.reportMetaChange({}, this.props.meta);
    }

    componentDidUpdate({ meta: prevMeta }) {
      this.reportMetaChange(prevMeta, this.props.meta);
    }

    render() {
      const { meta } = this.props;
      return <RawField {...R.omit(['onMetaChange'], this.props)} meta={this.getExtraMeta(meta)} />;
    }
  }

  return WithOnMetaChange;
};

@withStyles((theme) => ({ ...styles(theme), ...styles(theme).root }), {
  name: 'reduxFormMui',
})
@withOnMetaChange()
class RawTextField extends React.PureComponent {
  static propTypes = {
    multiline: PropTypes.bool,
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
    disableUnderline: PropTypes.bool,
  };

  static defaultProps = {
    disableUnderline: false,
  };

  renderView() {
    const {
      input: { name },
      label,
      multiline = false,
      adornment: { position = 'start', component = null } = {},
      adornmentLabel: { positionAdornmentLabel = 'start', componentAdornmentLabel = null } = {},
      istruncate = false,
      isWordBreak = false,
    } = this.props;
    let {
      input: { value },
    } = this.props;
    const changePostalLabel = R.prop('isusa', this.props) === undefined ? false : this.props.isusa;
    if (name === 'basicInfo.weight') {
      if (value === 0) value = '0.00';
      if (value > 0) value = roundFloatToFixedDecimal(parseFloat(value), 2).toFixed(2);
    }
    if (name === 'basicInfo.height') {
      if (value === 0) value = '0.0';
      if (value > 0) value = roundFloatToFixedDecimal(parseFloat(value), 1).toFixed(1);
    }

    return (
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={12}>
          <Typography variant="caption">
            {componentAdornmentLabel && positionAdornmentLabel === 'start' && (
              <span item>{componentAdornmentLabel}</span>
            )}
            {changePostalLabel ? 'Zip Code' : label}
            {componentAdornmentLabel && positionAdornmentLabel === 'end' && <span item>{componentAdornmentLabel}</span>}
          </Typography>
        </Grid>
        {component && position === 'start' && (
          <Grid item>
            <Typography color="textSecondary">{component}</Typography>
          </Grid>
        )}
        {istruncate && (
          <Grid item xs>
            <Typography noWrap style={{ whiteSpace: 'noWrap' }}>
              {value || ''}
            </Typography>
          </Grid>
        )}
        {isWordBreak && (
          <Grid item xs>
            <Typography style={{ wordBreak: 'break-word' }}>{value || '-'}</Typography>
          </Grid>
        )}
        {!istruncate && !isWordBreak && (
          <Grid item>
            <Typography variant="body1" style={{ whiteSpace: multiline ? 'pre-line' : 'normal' }}>
              {value || '-'}
            </Typography>
          </Grid>
        )}
        {component && position === 'end' && (
          <Grid item>
            <Typography color="textSecondary">{component}</Typography>
          </Grid>
        )}
      </Grid>
    );
  }

  renderEdit() {
    const {
      input,
      meta,
      fullWidth,
      required,
      label,
      hasHelperText = true,
      helperText = null,
      showHelperText = null,
      adornment: { position = 'start', component = null } = {},
      adornmentLabel: { positionAdornmentLabel = 'start', componentAdornmentLabel = null } = {},
      readonly,
      showStatus,
      classes,
      inputWidth = '100%',
      disableUnderline,
      placeholder = 'Enter ...',
      limit = null,
      shrink = true,
      useShrink = true,
      absoluteWholeNumber = false,
      labelStyle = null,
      ...props
    } = this.props;
    const { touched, error, warning } = meta;
    const { disabled } = props;

    // only allow whole and absolute numbers
    if (absoluteWholeNumber && input && input.value) {
      input.value = Number(`${input.value}`.replace(/[^0-9]/g, ''));
    }

    // do not allow leading zeros
    if (absoluteWholeNumber && input && input.value == 0) {
      input.value = '';
    }

    const statusAdornment = {
      position: 'end',
      component: (() => {
        const status = decideStatusFromMeta(meta);
        if (['pristine'].includes(status)) return null;
        const statusProps = {
          color: disabled ? 'disabled' : 'primary',
          classes: { colorPrimary: classes[status] },
        };
        const StatusComponent = {
          dirty: ModeEdit,
          touchedValid: CheckCircle,
          visiblyInvalid: Error,
        }[status];
        return <StatusComponent {...statusProps} />;
      })(),
    };

    const changePostalLabel = R.prop('isusa', this.props) === undefined ? false : this.props.isusa;
    const changePostalErrRequire = error ? R.replace('Postal Code', 'Zip Code', error) : error;
    const errorResult = changePostalLabel ? changePostalErrRequire : error;

    return (
      <FormControl error={touched && !!error} fullWidth={fullWidth} required={required}>
        <ThemeProvider theme={defaultMaterialTheme}>
          {useShrink
            ? label && (
                <InputLabel htmlFor={label} shrink={shrink} style={labelStyle}>
                  {componentAdornmentLabel && positionAdornmentLabel === 'start' && (
                    <span item>{componentAdornmentLabel}</span>
                  )}
                  {changePostalLabel ? 'Zip Code' : label}
                  {componentAdornmentLabel && positionAdornmentLabel === 'end' && (
                    <span item>{componentAdornmentLabel}</span>
                  )}
                </InputLabel>
              )
            : label && (
                <InputLabel htmlFor={label} style={labelStyle}>
                  {componentAdornmentLabel && positionAdornmentLabel === 'start' && (
                    <span item>{componentAdornmentLabel}</span>
                  )}
                  {changePostalLabel ? 'Zip Code' : label}
                  {componentAdornmentLabel && positionAdornmentLabel === 'end' && (
                    <span item>{componentAdornmentLabel}</span>
                  )}
                </InputLabel>
              )}
          <Input
            placeholder={placeholder}
            disableUnderline={disableUnderline}
            style={{ width: inputWidth }}
            // value={input.value || ''}
            // onChange={(e) => onChange && onChange( e.target.value || '' )}
            {...{
              [`${statusAdornment.position}Adornment`]: showStatus && (
                <InputAdornment position={statusAdornment.position}>{statusAdornment.component}</InputAdornment>
              ),
            }}
            {...{
              [`${position}Adornment`]: position && component && (
                <InputAdornment position={position}>{component}</InputAdornment>
              ),
            }}
            {...input}
            {...props}
          />
          {hasHelperText && (
            <FormHelperText style={{ whiteSpace: 'pre' }}>
              {(touched && (errorResult || warning)) || helperText || showHelperText}
            </FormHelperText>
          )}
          {limit && (
            <Typography
              variant="caption"
              style={{ textAlign: 'right', fontSize: '12px', marginTop: '-15px', color: '#9e9e9e' }}
            >
              {this.props.input.value.length} / {limit}
            </Typography>
          )}
        </ThemeProvider>
      </FormControl>
    );
  }

  render() {
    const { readonly } = this.props;

    return readonly ? this.renderView() : this.renderEdit();
  }
}

class TextField extends React.PureComponent {
  render() {
    const { name, helperText, ...props } = this.props;

    return (
      <div style={{ width: '100%' }}>
        <Field name={name} component={RawTextField} fullWidth {...props} />
        {helperText && (
          <Typography
            variant="caption"
            style={{ textAlign: 'right', fontSize: '12px', marginTop: '-15px', color: '#9e9e9e' }}
          >
            {helperText}
          </Typography>
        )}
      </div>
    );
  }
}

@withStyles((theme) => ({ ...styles(theme).status, ...styles(theme).root }), {
  name: 'reduxFormMui',
})
@withOnMetaChange()
class RawShowHidePasswordTextField extends React.PureComponent {
  static propTypes = {
    input: PropTypes.object.isRequired,
    label: PropTypes.string,
    className: PropTypes.string,
    required: PropTypes.bool,
  };

  state = { showPassword: false };

  render() {
    const {
      /* Field */
      input,
      meta: { touched, error, warning },
      /* FormControl */
      fullWidth,
      required,
      /* InputLabel */
      label = 'Password',
      /* FormHelperText */
      helperText = null,
      /* Input */
      disabled,
      classes,
      ...props
    } = this.props;

    return (
      <FormControl error={touched && !!error} fullWidth={fullWidth} required={required}>
        {label && <InputLabel htmlFor={label}>{label}</InputLabel>}
        <Input
          type={this.state.showPassword ? 'text' : 'password'}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                tabIndex={-1}
                onClick={() => !disabled && this.setState({ showPassword: !this.state.showPassword })}
                onMouseDown={(e) => e.preventDefault()}
              >
                {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          disabled={!!disabled}
          classes={R.pick(['underline', 'focused', 'error', 'disabled'], classes)}
          {...input}
          {...props}
        />
        <FormHelperText style={{ whiteSpace: 'none' }}>{(touched && (error || warning)) || helperText}</FormHelperText>
      </FormControl>
    );
  }
}

class ShowHidePasswordTextField extends React.PureComponent {
  render() {
    const { name, ...props } = this.props;
    return <Field name={name} component={RawShowHidePasswordTextField} fullWidth {...props} />;
  }
}

class RawPhone extends React.PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    landline: PropTypes.bool,
    delimiter: PropTypes.string,
    fullWidth: PropTypes.bool,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
  };

  state = {
    metas: {
      countryCode: R.clone(defaultMeta),
      areaCode: R.clone(defaultMeta),
      value: R.clone(defaultMeta),
    },
  };

  itemTemplate = ({ label: countryCode, iso, flag }) => (
    <Grid container justify="flex-start" alignItems="center" spacing={1}>
      <Grid item>
        <img src={flag} style={{ display: 'block' }} />
      </Grid>
      <Grid item>{iso}</Grid>
      <Grid item>({countryCode})</Grid>
    </Grid>
  );

  reportMetaChange = (metas) => {
    const { onMetaChange: onPhoneMetaChange } = this.props;
    const phoneMeta = R.pipe(
      R.unless(R.always(R.prop('landline', this.props)), R.omit(['areaCode'])),
      reduceMetas,
    )(metas);
    onPhoneMetaChange && onPhoneMetaChange(phoneMeta);
  };

  changeMeta = memoize((name) => (meta) =>
    this.setState(({ metas: prevMetas }) => {
      const nextMetas = update(prevMetas, { [name]: { $set: meta } });
      this.reportMetaChange(nextMetas);
      return { metas: nextMetas };
    }),
  );

  renderView() {
    const {
      [this.props.name]: {
        countryCode: {
          input: { value: { value: countryCode } = {} },
        },
        value: {
          input: { value },
        },
      },
      label,
      istruncate = false,
    } = this.props;

    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="caption">{label}</Typography>
        </Grid>
        <Grid item xs={12}>
          {istruncate ? (
            <Typography noWrap style={{ whiteSpace: 'noWrap' }}>
              {`+${countryCode} ${value}`}
            </Typography>
          ) : (
            <Typography variant="body1">{`+${countryCode} ${value}`}</Typography>
          )}
        </Grid>
      </Grid>
    );
  }

  renderEdit() {
    const { metas } = this.state;
    const {
      /* Fields */
      [this.props.name]: {
        countryCode: { input: countryCodeInput, meta: countryCodeMeta },
        areaCode: { input: areaCodeInput, meta: areaCodeMeta },
        value: { input: phoneNoInput, meta: phoneNoMeta },
      },
      label,
      landline,
      fullWidth,
      required,
      placeholder = '',
      disabled,
      readonly,
      updateisusa,
    } = this.props;

    // console.log('%cPhone', 'font-size: 12px; color: #00b3b3', this.state, this.props);

    return (
      <FormControl
        error={R.pipe(reduceMetas, R.prop('visiblyInvalid'))(metas)}
        fullWidth={!!fullWidth}
        required={!!required}
      >
        <FormLabel style={{ transformOrigin: 'top left', transform: 'translate(0, 1.5px) scale(0.75)' }}>
          {label}
        </FormLabel>
        <Grid container spacing={1}>
          <Grid item>
            <RawSelect
              updateisusa={updateisusa}
              itemTemplate={this.itemTemplate}
              renderValue={(value) => `+${value}`}
              input={countryCodeInput}
              meta={countryCodeMeta}
              onMetaChange={this.changeMeta('countryCode')}
              disabled={!!disabled}
              readonly={!!readonly}
            />
          </Grid>
          {landline && (
            <Grid item xs={2}>
              <RawTextField
                fullWidth={true}
                input={{
                  ...areaCodeInput,
                  onChange: (e) =>
                    areaCodeInput.onChange &&
                    areaCodeInput.onChange(normalize.toDigits(e.target.value, areaCodeInput.value)),
                }}
                meta={areaCodeMeta}
                onMetaChange={this.changeMeta('areaCode')}
                disabled={!!disabled}
                readonly={!!readonly}
                placeholder
              />
            </Grid>
          )}
          <Grid item xs>
            <RawTextField
              fullWidth={true}
              input={{
                ...phoneNoInput,
                onChange: (e) =>
                  phoneNoInput.onChange &&
                  phoneNoInput.onChange(normalize.toDigits(e.target.value, phoneNoInput.value)),
              }}
              meta={phoneNoMeta}
              onMetaChange={this.changeMeta('value')}
              disabled={!!disabled}
              readonly={!!readonly}
              placeholder
            />
          </Grid>
        </Grid>
      </FormControl>
    );
  }

  render() {
    const { readonly } = this.props;

    return readonly ? this.renderView() : this.renderEdit();
  }
}

class Phone extends React.PureComponent {
  render() {
    const names = [
      `${R.pathOr('phone', ['name'], this.props)}.countryCode`,
      `${R.pathOr('phone', ['name'], this.props)}.areaCode`,
      `${R.pathOr('phone', ['name'], this.props)}.value`,
    ];
    return <Fields names={names} component={RawPhone} fullWidth {...this.props} />;
  }
}

@withStyles(() => ({
  '@global': {
    'div::-webkit-scrollbar': {
      minWidth: '1px',
      minHeight: '1px',
    },
    'div::-webkit-scrollbar-thumb': {
      borderRadius: '8px',
      border: '2px solid white',
      backgroundColor: 'rgba(0, 0, 0, .5)',
    },
    'div::-webkit-scrollbar:vertical': {
      width: '13px',
    },
    'div::-webkit-scrollbar:horizontal': {
      height: '13px',
    },
  },
}))
@withStyles((theme) => ({ ...styles(theme).root }), { name: 'reduxFormMui' })
@withOnMetaChange()
class RawSelect extends React.PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
    disableUnderline: PropTypes.bool,
  };

  static defaultProps = {
    disabled: false,
    readonly: false,
    disableUnderline: false,
  };

  defaultItemTemplate = ({ label }) => label;

  renderView() {
    const {
      input: {
        value: { options = [], value },
      },
      label,
      adornmentLabel: { position = 'start', component = null } = {},
      labelStyle = null,
    } = this.props;

    return (
      <Grid container spacing={1}>
        {label && (
          <Grid item xs={12}>
            <Typography variant="caption">
              {position === 'start' && component && <span item>{component}</span>}
              {label}
              {position === 'end' && component && <span item>{component}</span>}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography variant="body1" style={labelStyle}>
            {switchProp('label', 'value', value, options) || '-'}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  renderEdit() {
    const {
      input: {
        value: { options = [], value },
        onChange,
        onBlur,
        onFocus,
      },
      label,
      itemTemplate: ItemTemplate = this.defaultItemTemplate,
      meta: { touched, error, warning },
      adornmentLabel: { position = 'start', component = null, adornmentAction = null } = {},
      hasHelperText = true,
      helperText = null,
      renderValue,
      disabled,
      placeholder = 'Select ...',
      readonly,
      useShrink = true,
      disableUnderline,
      classes,
      ...custom
    } = this.props;
    const className = classNames({
      [classes.disabled]: disabled,
      [classes.root]: !disableUnderline,
      [classes.underline]: !disableUnderline,
    });

    return (
      <FormControl error={touched && !!error} disabled={disabled} {...custom}>
        <ThemeProvider theme={defaultMaterialTheme}>
          {label && (
            <InputLabel htmlFor={label} shrink={useShrink}>
              {position === 'start' && component && (
                <span onClick={() => adornmentAction()} item>
                  {component}
                </span>
              )}
              {label}
              {position === 'end' && component && (
                <span onClick={() => adornmentAction()} item>
                  {component}
                </span>
              )}
            </InputLabel>
          )}
          <MuiSelect
            disableUnderline={disableUnderline}
            value={value || ''}
            onChange={(e) => onChange && onChange({ options, value: e.target.value || '' })}
            onFocus={onFocus}
            onBlur={onBlur && onBlur.bind(null, { options, value })}
            displayEmpty={true}
            renderValue={
              value === undefined || value === '' || value === null
                ? () => <Placeholder>{placeholder}</Placeholder>
                : renderValue
            }
          >
            {options &&
              options.map(({ id: optionKey, value: optionValue, ...props }) => (
                <MenuItem
                  style={{ width: '100%', height: '45px', marginRight: '3px' }}
                  key={optionKey || optionValue}
                  value={optionValue}
                >
                  <ItemTemplate {...props} />
                </MenuItem>
              ))}
          </MuiSelect>
          {hasHelperText && (
            <FormHelperText style={{ whiteSpace: 'pre' }}>
              {(touched && (error || warning)) || helperText}
            </FormHelperText>
          )}
        </ThemeProvider>
      </FormControl>
    );
  }

  render() {
    const { readonly } = this.props;

    return readonly ? this.renderView() : this.renderEdit();
  }
}

class Select extends React.PureComponent {
  render() {
    const { name, ...props } = this.props;

    return <Field name={name} component={RawSelect} fullWidth {...props} />;
  }
}

@withStyles((theme) => ({ ...styles(theme).root }), { name: 'reduxFormMui' })
@withOnMetaChange()
class RawMultiSelect extends React.PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
    disableUnderline: PropTypes.bool,
  };

  static defaultProps = {
    disabled: false,
    readonly: false,
    disableUnderline: false,
  };

  defaultItemTemplate = ({ label }) => label;

  renderView() {
    const {
      input: {
        value: { options = [], value },
      },
      label,
    } = this.props;
    const showValue = joinSpecificValue('label', 'value', value, options);

    return (
      <Grid container spacing={1}>
        {label && (
          <Grid item xs={12}>
            <Typography variant="caption">{label}</Typography>
          </Grid>
        )}
        <Grid item xs={12}>
        <Typography variant="body1">{showValue || 'Not Applicable'}</Typography>
        </Grid>
      </Grid>
    );
  }

  renderEdit() {
    const {
      input: {
        value: { options = [], value },
        onChange,
        onBlur,
        onFocus,
      },
      label,
      itemTemplate: ItemTemplate = this.defaultItemTemplate,
      meta: { touched, error, warning },
      hasHelperText = true,
      helperText = null,
      renderValue,
      disabled,
      readonly,
      useShrink = true,
      disableUnderline,
      maxSelectedOptions = null,
      classes,
      ...custom
    } = this.props;

    const className = classNames({
      [classes.disabled]: disabled,
      [classes.inkbar]: !disableUnderline,
      [classes.underline]: !disableUnderline,
    });
    const checkBoxChecked = value || [];

    return (
      <FormControl error={touched && !!error} disabled={disabled} {...custom}>
        <ThemeProvider theme={defaultMaterialTheme}>
          {label && (
            <InputLabel
              htmlFor={label}
              style={{ color: touched && !!error ? '#f44336' : '#737373' }}
              shrink={useShrink}
            >
              {label}
            </InputLabel>
          )}
          <MuiSelect
            multiple
            value={value || []}
            onChange={(e) => onChange && onChange({ options, value: e.target.value || [] })}
            onFocus={onFocus}
            onBlur={onBlur && onBlur.bind(null, { options, value })}
            displayEmpty={true}
            renderValue={(selected) =>
              !value || value.length === 0 ? (
                <Placeholder>Select ...</Placeholder>
              ) : (
                joinSpecificValue('label', 'value', selected, options)
              )
            }
          >
            {options &&
              options.map(({ id: optionKey, value: optionValue, ...props }) => (
                <MenuItem style={{ width: '100%' }} key={optionKey || optionValue} value={optionValue} disabled={maxSelectedOptions === null ? false : checkBoxChecked.length >= 5 && !checkBoxChecked.includes(optionValue)}>
                  <MuiCheckbox checked={R.indexOf(optionValue, checkBoxChecked) > -1} />
                  <ItemTemplate {...props} />
                </MenuItem>
              ))}
          </MuiSelect>
          {hasHelperText && (
            <FormHelperText style={{ whiteSpace: 'pre' }}>
              {(touched && (error || warning)) || helperText}
            </FormHelperText>
          )}
        </ThemeProvider>
      </FormControl>
    );
  }

  render() {
    const { readonly } = this.props;

    return readonly ? this.renderView() : this.renderEdit();
  }
}

class MultiSelect extends React.PureComponent {
  render() {
    const { name, ...props } = this.props;

    return <Field name={name} component={RawMultiSelect} fullWidth {...props} />;
  }
}
const ITEM_HEIGHT = 48;
@withStyles((theme) => ({
  // We had to use a lot of global selectors in order to style react-select.
  // We are waiting on https://github.com/JedWatson/react-select/issues/1679
  // to provide a much better implementation.
  // Also, we had to reset the default style injected by the library.
  '@global': {
    '.Select-control': {
      display: 'flex',
      alignItems: 'center',
      border: 0,
      height: 'auto',
      background: 'transparent !important',
      '&:hover': {
        boxShadow: 'none',
      },
    },
    '.Select-multi-value-wrapper': {
      flexGrow: 1,
      display: 'flex',
      flexWrap: 'wrap',
    },
    '.Select--multi .Select-input': {
      margin: 0,
    },
    '.Select.has-value.is-clearable.Select--single > .Select-control .Select-value': {
      padding: 0,
    },
    '.Select-noresults': {
      padding: theme.spacing(2),
    },
    '.Select-input': {
      display: 'inline-flex !important',
      padding: 0,
      height: 'auto',
    },
    '.Select-input input': {
      background: 'transparent',
      border: 0,
      padding: 0,
      cursor: 'default',
      display: 'inline-block',
      fontFamily: 'inherit',
      fontSize: 'inherit',
      margin: 0,
      outline: 0,
    },
    '.Select-placeholder, .Select--single > .Select-control .Select-value': {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      color: 'inherit',
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.pxToRem(16),
      padding: 0,
    },
    '.Select-placeholder': {
      opacity: 0.42,
      color: theme.palette.common.black,
    },
    '.Select-menu-outer': {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[2],
      position: 'absolute',
      left: 0,
      top: `calc(100% + ${theme.spacing()}px)`,
      width: '100%',
      zIndex: '99999 !important',
      maxHeight: ITEM_HEIGHT * 4.5,
    },
    '.Select.is-focused:not(.is-open) > .Select-control': {
      boxShadow: 'none',
    },
    '.Select-menu': {
      maxHeight: ITEM_HEIGHT * 4.5,
      overflowY: 'auto',
    },
    '.Select-arrow-zone, .Select-clear-zone': {
      color: theme.palette.action.active,
      cursor: 'pointer',
      height: 19,
      width: 19,
      zIndex: 1,
      pointerEvents: 'none',
    },
    '.Select-arrow-zone > svg, .Select-clear-zone > svg': {
      position: 'relative',
      top: -2,
      right: 5,
    },
    // Only for screen readers. We can't use display none.
    '.Select-aria-only': {
      position: 'absolute',
      overflow: 'hidden',
      clip: 'rect(0 0 0 0)',
      height: 1,
      width: 1,
      margin: -1,
    },
    'div::-webkit-scrollbar': {
      minWidth: '1px',
      minHeight: '1px',
    },
    'div::-webkit-scrollbar-thumb': {
      borderRadius: '8px',
      border: '2px solid white',
      backgroundColor: 'rgba(0, 0, 0, .5)',
    },
    'div::-webkit-scrollbar:vertical': {
      width: '13px',
    },
    'div::-webkit-scrollbar:horizontal': {
      height: '13px',
    },
  },
}))
@withStyles(
  (theme) => ({
    ...styles(theme).root,
    clear: {
      '&:hover': {
        color: '#D0021B',
      },
    },
  }),
  { name: 'reduxFormMui' },
)
@withOnMetaChange()
class RawTypeSelect extends React.PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
  };
  static SelectWrapped = class extends React.PureComponent {
    defaultItemTemplate = ({ label }) => label;

    render() {
      const {
        classes,
        options,
        selectedValue,
        selectValue,
        updateisusa,
        itemTemplate: ItemTemplate = this.defaultItemTemplate,
        menuwidth,
        ...rest
      } = this.props;

      if (updateisusa !== undefined) {
        updateisusa(R.prop('value', this.props));
      }

      return (
        <ReactVirtualizedSelect
          menuContainerStyle={{ zIndex: 9999, width: menuwidth ? menuwidth : '100%' }}
          options={options}
          optionRenderer={({
            key,
            option: { id: optionId, value: optionValue, ...optionProps },
            style,
            selectValue: rvsSelectValue,
          }) => {
            const selected = selectedValue === optionValue;

            return (
              <MenuItem
                selected={selected}
                key={optionId || key}
                onClick={() => {
                  rvsSelectValue(optionValue);
                  // BUG: ReactVirtualizedSelect doesn't trigger onChange passed to it as expected
                  selectValue({ options, value: optionValue || '' });
                }}
                style={{
                  ...style,
                  boxSizing: 'border-box',
                  width: '100%',
                  fontWeight: selected ? 500 : 400,
                  zIndex: 9999,
                  whiteSpace: 'unset',
                  wordBreak: 'break-all',
                }}
              >
                <ItemTemplate {...optionProps} />
              </MenuItem>
            );
          }}
          optionHeight={48} // NOTE: MenuItem default's height is 48px
          simpleValue
          noResultsText={<Typography>No results found</Typography>}
          arrowRenderer={({ isOpen }) =>
            isOpen ? <ArrowDropUp style={{ paddingLeft: '10px' }} /> : <ArrowDropDown style={{ paddingLeft: '10px' }} />
          }
          clearRenderer={() => <Clear className={classes.clear} onClick={() => selectValue({ options, value: '' })} />}
          {...rest}
        />
      );
    }
  };

  renderView() {
    const {
      input: {
        value: { options = [], value },
      },
      label,
      adornment: { position = 'start', component = null } = {},
    } = this.props;

    return (
      <Grid container spacing={1}>
        {label && (
          <Grid item xs={12}>
            <Typography variant="caption">{label}</Typography>
          </Grid>
        )}
        {position === 'start' && component && <Grid item>{component}</Grid>}
        <Grid
          item
          style={{
            margin: '0px 10px 0px 0px',
            overflow: 'hidden',
            wordWrap: 'break-word',
          }}
        >
          <Typography variant="body1">{switchProp('label', 'value', value, options) || '-'}</Typography>
        </Grid>
        {position === 'end' && component && <Grid item>{component}</Grid>}
      </Grid>
    );
  }

  renderEdit() {
    const {
      /* Field */
      input: {
        value: { options = [], value: selectedValue },
        onChange,
        onFocus,
        onBlur,
      },
      meta: { touched, error, warning },
      /* FormControl */
      fullWidth,
      required,
      /* InputLabel */
      label,
      shrink = true,
      /* FormHelperText */
      hasHelperText = true,
      helperText = null,
      /* Input */
      clearable = false,
      adornment: { position = 'start', component = null, width = null } = {},
      classes,
      disableUnderline = false,
      notApplicable = false,
      inputWidth = '100%',
      inputHeight = 'auto',
      ...rest
    } = this.props;

    return notApplicable ? (
      <Grid container spacing={1}>
        {label && (
          <Grid item xs={12}>
            <Typography variant="caption">{label}</Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography variant="body1">-</Typography>
        </Grid>
      </Grid>
    ) : (
      <FormControl error={touched && !!error} fullWidth={fullWidth} required={required}>
        <ThemeProvider theme={defaultMaterialTheme}>
          {label && (
            <InputLabel htmlFor={label} shrink={shrink}>
              {label}
            </InputLabel>
          )}
          <Input
            disableUnderline={disableUnderline}
            inputComponent={RawTypeSelect.SelectWrapped}
            placeholder="Select ..."
            inputProps={{
              classes,
              clearable,
              options,
              selectedValue,
              selectValue: onChange,
              ...rest,
            }}
            style={{ width: inputWidth, whiteSpace: 'unset', wordBreak: 'break-all' }}
            value={selectedValue || ''}
            onFocus={onFocus}
            onBlur={onBlur.bind(null, { options, value: selectedValue })}
            {...{
              [`${position}Adornment`]: position && component && (
                <InputAdornment style={{ width }} position={position}>
                  {component}
                </InputAdornment>
              ),
            }}
            {...rest}
          />
          {hasHelperText && (
            <FormHelperText style={{ whiteSpace: 'pre' }}>
              {(touched && (error || warning)) || helperText}
            </FormHelperText>
          )}
        </ThemeProvider>
      </FormControl>
    );
  }

  render() {
    const { readonly } = this.props;

    return readonly ? this.renderView() : this.renderEdit();
  }
}

class TypeSelect extends React.PureComponent {
  render() {
    const { name, ...props } = this.props;

    return <Field name={name} component={RawTypeSelect} fullWidth {...props} />;
  }
}

@withOnMetaChange()
class RawSwitch extends React.PureComponent {
  render() {
    const {
      input: { value: checked, onChange, onBlur, onFocus },
      variant = null,
      labelIcon = null,
      labelStyle = null,
      label,
      meta: { touched, error, warning },
      helperText = null,
      disabled = false,
      ...custom
    } = this.props;

    return (
      <FormControl error={touched && !!error} {...custom}>
        {variant ? (
          <Typography variant="caption" style={labelStyle}>
            {label} {labelIcon && <span> {labelIcon}</span>}
          </Typography>
        ) : (
          <FormLabel component="legend" style={labelStyle}>
            {label} {labelIcon && <span> {labelIcon}</span>}
          </FormLabel>
        )}

        <MuiSwitch
          aria-label={label}
          checked={checked}
          onChange={(e, switchChecked) => onChange(switchChecked)}
          onFocus={(e) => onFocus(e)}
          onBlur={onBlur.bind(null, checked)}
          disabled={disabled}
        />
        <FormHelperText style={{ whiteSpace: 'pre' }}>{(touched && (error || warning)) || helperText}</FormHelperText>
      </FormControl>
    );
  }
}

class Switch extends React.PureComponent {
  render() {
    const { name, ...props } = this.props;

    return <Field name={name} component={RawSwitch} fullWidth {...props} />;
  }
}

@withStyles({
  checkboxStyle: {
    '&&:hover': {
      backgroundColor: 'transparent',
    },
  },
})
@withOnMetaChange()
class RawCheckbox extends React.PureComponent {
  render() {
    const {
      input: { value: checked, onChange, onFocus, onBlur },
      label,
      /* InputLabel */
      shrink = true,
      meta: { touched, error, warning },
      helperText = null,
      styleCheckbox = null,
      disabled = false,
      classes,
      ...custom
    } = this.props;

    return (
      <FormControl error={touched && !!error} {...custom}>
        {label && (
          <InputLabel shrink={shrink} htmlFor={label}>
            {label}
          </InputLabel>
        )}
        <MuiCheckbox
          aria-label={label}
          checked={checked}
          className={classes.checkboxStyle}
          style={styleCheckbox}
          onChange={(e, checkBoxChecked) => onChange(checkBoxChecked)}
          onFocus={(e) => onFocus(e)}
          onBlur={onBlur.bind(null, checked)}
          disabled={disabled}
        />
        <FormHelperText style={{ whiteSpace: 'pre' }}>{(touched && (error || warning)) || helperText}</FormHelperText>
      </FormControl>
    );
  }
}

class Checkbox extends React.PureComponent {
  render() {
    const { name, ...props } = this.props;

    return <Field name={name} component={RawCheckbox} fullWidth {...props} />;
  }
}

@withOnMetaChange()
class RawCheckboxRightLabelGroup extends React.PureComponent {
  render() {
    const {
      input: { value: checked, onChange, onFocus, onBlur },
      label,
      required,
      hasHelperText = true,
      helperText = null,
      /* InputLabel */
      meta: { touched, error, warning },
      ...custom
    } = this.props;

    return (
      <FormControl error={touched && !!error} required={required} {...custom}>
        {
          <FormControlLabel
            control={
              <MuiCheckbox
                label={label}
                aria-label={label}
                checked={checked}
                onChange={(e, checkBoxChecked) => onChange(checkBoxChecked)}
                onFocus={(e) => onFocus(e)}
                onBlur={onBlur.bind(null, checked)}
              />
            }
            label={<Typography variant="body1">{label}</Typography>}
            labelplacement="end"
          />
        }
        {hasHelperText && (
          <FormHelperText style={{ whiteSpace: 'pre', marginTop: '-5px' }}>
            {(touched && (error || warning)) || helperText}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
}

const radioStyle = (theme) => ({
  radio: {
    '&$checked': {
      color: '#FF5722',
    },
  },
  checked: {},
  description: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '16px',
    letterSpacing: '0.1px',
  },
});

class CheckboxRightLabelGroup extends React.PureComponent {
  render() {
    const { name, ...props } = this.props;

    return <Field name={name} component={RawCheckboxRightLabelGroup} fullWidth {...props} />;
  }
}
@withStyles(radioStyle, { index: 1 })
@withOnMetaChange()
class RawRadioGroup extends React.PureComponent {
  static propTypes = {
    readonly: PropTypes.bool,
  };

  renderView() {
    const {
      input: {
        value: { value },
      },
      label,
      labelStyle = null,
    } = this.props;

    return (
      <Grid container justify="flex-start" alignItems="flex-start">
        <Grid item>
          {/* <Typography variant='body1' style={style}>{switchProp('label', 'value', value, options)}</Typography> */}
          <Typography variant="caption" style={labelStyle}>
            {label}
          </Typography>
          <Typography variant="body1" style={{ marginTop: '6px' }}>
            {commonUtil.toTitleCase(value)}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  renderEdit() {
    const {
      classes,
      input: {
        value: { options = [], value },
        onChange,
        onBlur,
        onFocus,
      },
      label,
      meta: { touched, error, warning },
      row,
      hasHelperText = true,
      helperText = null,
      disabled = false,
      readonly,
      labelStyle = null,
      customPadding = '95px',
      ...custom
    } = this.props;

    const disabledEdit = disabled;

    return (
      <FormControl component="fieldset" error={touched && !!error} {...custom}>
        <FormLabel component="legend" style={labelStyle}>
          {label}
        </FormLabel>
        <MuiRadioGroup
          aria-label={label}
          row={row}
          value={value || ''}
          onChange={(evt, value) => onChange({ options, value })}
          onFocus={(e) => onFocus(e)}
          onBlur={onBlur.bind(null, { options, value })}
        >
          {options.map(
            (
              {
                id: optionKey,
                value: optionValue,
                label: optionLabel,
                description,
                disabled = disabledEdit,
                labelStyle = null,
                descriptionStyle = null,
                ...optionProps
              },
              index,
            ) => (
              <Box key={optionKey || optionValue} style={{ flexDirection: 'column' }}>
                <FormControlLabel
                  label={labelStyle ? <Typography style={labelStyle}>{optionLabel}</Typography> : optionLabel}
                  style={{ paddingRight: index === 0 ? customPadding : '0px' }}
                  value={optionValue}
                  control={<Radio classes={{ root: classes.radio, checked: classes.checked }} disabled={disabled} />}
                  {...optionProps}
                />
                {descriptionStyle ? (
                  <Typography style={descriptionStyle}>{description}</Typography>
                ) : (
                  <Typography variant="body2">{description}</Typography>
                )}
              </Box>
            ),
          )}
        </MuiRadioGroup>
        {hasHelperText && (
          <FormHelperText style={{ whiteSpace: 'pre' }}>{(touched && (error || warning)) || helperText}</FormHelperText>
        )}
      </FormControl>
    );
  }

  render() {
    const { readonly } = this.props;

    return readonly ? this.renderView() : this.renderEdit();
  }
}

class RadioGroup extends React.PureComponent {
  render() {
    const { name, ...props } = this.props;

    return <Field name={name} component={RawRadioGroup} fullWidth {...props} />;
  }
}

@withOnMetaChange()
class RawCheckboxGroup extends React.PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
  };

  render() {
    const {
      input: {
        value: { options = [], checkeds = {} },
        onChange,
        onBlur,
        onFocus,
      },
      label,
      meta: { touched, error, warning },
      row,
      controlProps,
      hasHelperText = true,
      helperText = null,
      formGroupProps,
      disabled,
      readonly,
      customWidth = null,
      ...custom
    } = this.props;

    return (
      <FormControl component="fieldset" error={touched && !!error} disabled={!!disabled || !!readonly} {...custom}>
        <FormLabel component="legend">{label}</FormLabel>
        <FormGroup row={row} {...formGroupProps}>
          <Grid container>
            {options.map(
              ({
                id: optionKey,
                value: optionValue,
                label: optionLabel,
                tooltiptext: optionTooltipText,
                disabled: optionDisabled,
                ...optionProps
              }) => (
                <Grid key={optionKey || optionValue} item style={{ width: customWidth }}>
                  <Grid container alignContent="flex-start" alignItems="center" spacing={0}>
                    <Grid item>
                      <FormControlLabel
                        label={
                          <Typography variant={'body1'} style={{ fontSize: '14px' }}>
                            {optionLabel}
                          </Typography>
                        }
                        control={
                          <MuiCheckbox
                            disabled={optionDisabled}
                            disableRipple={true}
                            value={optionValue}
                            checked={!!checkeds[optionValue]}
                            onChange={(e) =>
                              onChange({
                                options,
                                checkeds: { ...checkeds, [optionValue]: e.target.checked },
                              })
                            }
                            onFocus={(e) => onFocus(e)}
                            onBlur={onBlur.bind(null, { options, checkeds })}
                            {...controlProps}
                          />
                        }
                        {...optionProps}
                      />
                    </Grid>
                    {optionTooltipText && (
                      <Grid item style={{ marginLeft: -10 }}>
                        <InfoTooltip
                          placement="bottom-right"
                          title={
                            <React.Fragment>
                              <Typography
                                style={{
                                  fontFamily: 'Roboto',
                                  fontSize: '16px',
                                  color: '#FFFFFF',
                                  lineHeight: '19px',
                                  letterSpacing: '0.5px',
                                }}
                                color="inherit"
                              >
                                {optionTooltipText}
                              </Typography>
                            </React.Fragment>
                          }
                        >
                          <InfoOutline style={{ height: 18, width: 18, color: '#9E9E9E' }} />
                        </InfoTooltip>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              ),
            )}
          </Grid>
        </FormGroup>
        {hasHelperText && (
          <FormHelperText style={{ whiteSpace: 'pre' }}>{(touched && (error || warning)) || helperText}</FormHelperText>
        )}
      </FormControl>
    );
  }
}

class CheckboxGroup extends React.PureComponent {
  render() {
    const { name, ...props } = this.props;

    return <Field name={name} component={RawCheckboxGroup} fullWidth {...props} />;
  }
}

@withOnMetaChange()
class RawCheckboxLabel extends React.PureComponent {
  static propTypes = {
    readonly: PropTypes.bool,
  };

  renderView() {
    const { label, labelStyle = null } = this.props;

    return (
      <Grid container justify="center">
        <Grid item>
          <Typography variant="body1" style={labelStyle}>
            {label}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  renderEdit() {
    const {
      input: { value, onChange, onBlur, onFocus },
      label,
      link = true,
      controlProps,
      readonly,
      labelStyle = null,
      cmConfig = null,
      ...custom
    } = this.props;

    return (
      <FormControlLabel
        label={
          <Typography variant="body1" style={labelStyle}>
            {label}
          </Typography>
        }
        htmlFor={link ? null : 'none'}
        control={
          <MuiCheckbox
            disableRipple={true}
            value={label}
            checked={!!value}
            onChange={(e) => {
              if (this.props.hasExistCMEnabled) {
                this.props.handleShowProgramValidationDialog('DUPLICATE_VITALS');
              } else {
                onChange(e.target.checked);
              }
            }}
            onFocus={(e) => onFocus(e)}
            onBlur={onBlur.bind(null, value)}
            {...controlProps}
          />
        }
        {...custom}
      />
    );
  }

  render() {
    const { readonly } = this.props;

    return readonly ? this.renderView() : this.renderEdit();
  }
}

class CheckboxLabel extends React.PureComponent {
  render() {
    const { name, ...props } = this.props;

    return <Field name={name} component={RawCheckboxLabel} {...props} />;
  }
}

@withStyles(radioStyle, { index: 1 })
@withOnMetaChange()
class RawRadioboxLabel extends React.PureComponent {
  static propTypes = {
    readonly: PropTypes.bool,
  };

  renderView() {
    const { label, labelStyle = null } = this.props;

    return (
      <Grid container justify="center">
        <Grid item>
          <Typography variant="body1" style={labelStyle}>
            {label}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  renderEdit() {
    const {
      classes,
      input: { value, onChange, onBlur, onFocus },
      label,
      labelStyle = null,
      link = true,
      controlProps,
      controlValue,
      readonly,
      ...custom
    } = this.props;

    return (
      <FormControlLabel
        label={
          <Typography variant="body1" style={labelStyle}>
            {label}
          </Typography>
        }
        htmlFor={link ? null : 'none'}
        value={controlValue}
        control={
          <Radio
            classes={{ root: classes.radio, checked: classes.checked }}
            // disableRipple={true}
            // value={label}
            // checked={!!value}
            onChange={(e) => onChange(e.target.checked)}
            onFocus={(e) => onFocus(e)}
            onBlur={onBlur.bind(null, value)}
            {...controlProps}
          />
        }
        {...custom}
      />
    );
  }

  render() {
    const { readonly } = this.props;

    return readonly ? this.renderView() : this.renderEdit();
  }
}

class RadioboxLabel extends React.PureComponent {
  render() {
    const { name, ...props } = this.props;

    return <Field name={name} component={RawRadioboxLabel} {...props} />;
  }
}

@withStyles((theme) => ({ ...styles(theme).root }), { name: 'reduxFormMui' })
@withOnMetaChange()
class RawDatePicker extends React.PureComponent {
  static propTypes = {
    containerProps: PropTypes.shape({
      justify: PropTypes.string,
      alignItems: PropTypes.string,
      spacing: PropTypes.number,
    }),
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
  };

  renderView = () => {
    const infoAdormentValue = { infoAdornmentPosition: 'end', infoAdornmentComponent: null };
    const {
      input: { value },
      label,
      dateFormat = 'DD-MM-YYYY',
      invalidLabel = '-',
      containerProps,
      removeIcon = true,
      containerStyle = null,
      infoAdornment = infoAdormentValue,
    } = this.props;
    const { infoAdornmentPosition, infoAdornmentComponent } = infoAdornment;

    return (
      <Grid container spacing={1} alignItems="center" {...containerProps} style={containerStyle}>
        {infoAdornmentPosition === 'start' && infoAdornmentComponent && (
          <Grid style={{ padding: 0, zIndex: 9 }} item xs>
            {infoAdornmentComponent}
          </Grid>
        )}
        <Grid item xs={infoAdornmentComponent ? 10 : 12}>
          <Typography variant="caption">{label}</Typography>
        </Grid>
        {infoAdornmentPosition === 'end' && infoAdornmentComponent && (
          <Grid style={{ padding: 0, zIndex: 9 }} item xs>
            {infoAdornmentComponent}
          </Grid>
        )}
        <Grid item>
          <Typography variant="body1">
            {R.ifElse(
              (v) => v.isValid(),
              (v) => v.format(dateFormat),
              R.always(invalidLabel),
            )(moment(value))}
          </Typography>
        </Grid>
        {!removeIcon && (
          <Grid item>
            <EventAvailable />
          </Grid>
        )}
      </Grid>
    );
  };

  renderEdit() {
    const infoAdormentValue = { infoAdornmentPosition: 'end', infoAdornmentComponent: null };
    // Check user's web browser
    const checkAgent = window.navigator.userAgent;
    const trident = checkAgent.indexOf('Trident/');
    let ieBrowser = false;
    if (trident > 0) {
      const rv = checkAgent.indexOf('rv:');
      const ie = parseInt(checkAgent.substring(rv + 3, checkAgent.indexOf('.', rv)), 10);
      ieBrowser = ie === 11;
    }
    const {
      input: { value, onChange, onBlur, onFocus },
      meta: { touched, error, warning },
      helperText = null,
      adornment: { position = 'end', Component = () => <img src={IconCalendar} style={{ marginRight: 2 }} /> } = {},
      infoAdornment = infoAdormentValue,
      dateFormat = 'DD-MMM-YYYY',
      invalidLabel = '',
      label = '',
      removeIcon = false,
      disablePast = false,
      additionalStyle = null,
      maxDate = new Date('2100-01-01'),
      isEndDate = false,
      inputLabelProps = {},
      placeholder = '',
      minDate = moment(new Date('1900-01-01')),
      isPeriod = false,
      ...rest
    } = this.props;
    const { infoAdornmentPosition, infoAdornmentComponent } = infoAdornment;

    const passedThroughProps = R.omit(['containerProps'], rest);

    return (
      <Grid container style={additionalStyle && { ...additionalStyle, marginTop: value ? 'auto' : '0px' }}>
        {infoAdornmentPosition === 'start' && infoAdornmentComponent && (
          <Grid style={{ padding: 0, zIndex: 9 }} item xs>
            {infoAdornmentComponent}
          </Grid>
        )}
        <Grid item xs={infoAdornmentComponent ? 10 : 12} style={{ padding: 0 }}>
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <ThemeProvider theme={defaultMaterialTheme}>
              {isEndDate ? (
                <MuiDatePicker
                  value={value || null}
                  format={dateFormat}
                  invalidLabel={invalidLabel}
                  emptyLabel={invalidLabel}
                  label={label}
                  readOnly={true}
                  InputProps={{
                    disableUnderline: true,
                    [`${position}Adornment`]: position && Component && !removeIcon && (
                      <InputAdornment position={position}>
                        <Component style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                      </InputAdornment>
                    ),
                  }}
                />
              ) : (
                <MuiDatePicker
                  value={value || null}
                  onChange={(newValue) => {
                    onFocus(); // HACK: redux-form needs this to set 'visited' properly
                    onChange(newValue);
                    onBlur(newValue); // HACK: redux-form needs this to set 'touched' properly
                  }}
                  format={dateFormat}
                  invalidLabel={invalidLabel}
                  emptyLabel=""
                  InputLabelProps={inputLabelProps}
                  placeholder={placeholder}
                  label={label}
                  disablePast={!!disablePast}
                  error={touched && !!error}
                  minDate={isPeriod ? minDate.format(dateFormat) : minDate}
                  maxDate={maxDate}
                  helperText={(touched && (error || warning)) || helperText}
                  InputProps={{
                    [`${position}Adornment`]: position && Component && !removeIcon && (
                      <InputAdornment position={position}>
                        <Component style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                      </InputAdornment>
                    ),
                  }}
                  {...passedThroughProps}
                />
              )}
            </ThemeProvider>
          </MuiPickersUtilsProvider>
        </Grid>
        {infoAdornmentPosition === 'end' && infoAdornmentComponent && (
          <Grid style={{ padding: 0, zIndex: 9 }} item xs>
            {infoAdornmentComponent}
          </Grid>
        )}
      </Grid>
    );
  }

  render() {
    const { readonly } = this.props;

    return readonly ? this.renderView() : this.renderEdit();
  }
}

class DatePicker extends React.PureComponent {
  render() {
    const { name, ...props } = this.props;

    return <Field name={name} component={RawDatePicker} fullWidth {...props} />;
  }
}

@withStyles((theme) => ({ ...styles(theme).root }), { name: 'reduxFormMui' })
@withOnMetaChange()
class RawTimePicker extends React.PureComponent {
  static propTypes = {
    containerProps: PropTypes.shape({
      justify: PropTypes.string,
      alignItems: PropTypes.string,
      spacing: PropTypes.number,
    }),
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
  };

  state = {
    selectedTime: '',
    valueTime: '',
  };

  componentDidMount() {
    const time = this.getDefaultTime();
    this.setState({ selectedTime: time });
  }

  getDefaultTime = () => {
    const date = new Date();
    date.setHours(0, 0, 0); // default hour hand to 12 and minute hand to 0 min.
    return date;
  };

  renderView = () => {
    const {
      input: { value },
      label,
      timeFormat = 'HH:mm:ss',
      containerProps,
      removeIcon = false,
    } = this.props;

    return (
      <Grid container spacing={1} alignItems="center" {...containerProps}>
        <Grid item xs={12}>
          <Typography variant="caption">{label}</Typography>
        </Grid>
        <Grid item>{moment(value).format(timeFormat)}</Grid>
        <Grid item>{!removeIcon && <AlarmOn />}</Grid>
      </Grid>
    );
  };

  renderEdit() {
    const { selectedTime } = this.state;
    const {
      input: { value, onChange, onBlur, onFocus },
      label,
      meta: { touched, error, warning },
      helperText = null,
      adornment: {
        position = 'end',
        component: Component = () => (
          <IconButton>
            <AddAlarm />
          </IconButton>
        ),
      } = {},
      timeFormat = 'HH:mm:ss',
      useIconCalendar = false,
      readonly,
      classes,
      timeStyle = null,
      keyboard = false,
      autoOk = false,
      ampm = true,
      inputsdornmentstyle = null,
      placeholder = '',
      ...rest
    } = this.props;

    const passedThroughProps = R.omit(['containerProps'], rest);

    return (
      <Grid style={timeStyle}>
        {!keyboard ? (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            {!value ? (
              <MuiTimePicker
                style={{ width: '100%' }}
                value={selectedTime}
                onChange={(newValue) => {
                  onFocus(); // HACK: redux-form needs this to set 'visited' properly
                  onChange(newValue);
                  onBlur(); // HACK: redux-form needs this to set 'touched' properly
                }}
                autoOk={autoOk}
                ampm={ampm}
                label={label}
                keyboardIcon={<AddAlarm fontSize="inherit" />}
                format=" "
                invalidLabel=" "
                placeholder={placeholder}
                error={touched && !!error}
                helperText={(touched && (error || warning)) || helperText}
                required={this.props.required}
                InputProps={
                  !keyboard && {
                    [`${position}Adornment`]: position && Component && (
                      <React.Fragment>
                        <div
                          style={{
                            fontFamily: 'Roboto',
                            fontSize: '16px',
                            fontWeight: '400',
                            lineHeight: '19px',
                            letterSpacing: '0.5px',
                            color: '#aaa',
                            position: 'absolute',
                            left: 0,
                          }}
                        >
                          {placeholder}
                        </div>
                        <InputAdornment style={{ marginLeft: '0px' }} position={position}>
                          {!useIconCalendar && (
                            <Component style={{ color: 'rgba(0, 0, 0, 0.54)', marginLeft: '20px' }} />
                          )}
                          {useIconCalendar && (
                            <img
                              src={IconCalendar}
                              style={{ marginRight: 2, color: 'rgba(0, 0, 0, 0.54)', marginLeft: '20px' }}
                            />
                          )}
                        </InputAdornment>
                      </React.Fragment>
                    ),
                    classes,
                  }
                }
              />
            ) : (
              <MuiTimePicker
                value={value}
                onChange={(newValue) => {
                  onFocus(); // HACK: redux-form needs this to set 'visited' properly
                  onChange(newValue);
                  onBlur(); // HACK: redux-form needs this to set 'touched' properly
                }}
                autoOk={autoOk}
                ampm={ampm}
                label={label}
                keyboardIcon={<AddAlarm fontSize="inherit" />}
                format={timeFormat}
                invalidLabel=""
                placeholder={placeholder}
                error={touched && !!error}
                helperText={(touched && (error || warning)) || helperText}
                InputProps={
                  !keyboard && {
                    [`${position}Adornment`]: position && Component && (
                      <InputAdornment position={position} style={inputsdornmentstyle}>
                        {!useIconCalendar && <Component style={{ color: 'rgba(0, 0, 0, 0.54)', marginLeft: '20px' }} />}
                        {useIconCalendar && (
                          <img
                            src={IconCalendar}
                            style={{ marginRight: 2, color: 'rgba(0, 0, 0, 0.54)', marginLeft: '20px' }}
                          />
                        )}
                      </InputAdornment>
                    ),
                    classes,
                  }
                }
                {...passedThroughProps}
              />
            )}
          </MuiPickersUtilsProvider>
        ) : (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <ThemeProvider theme={defaultMaterialTheme}>
              <KeyboardTimePicker
                value={value}
                onChange={(newValue) => {
                  onFocus();
                  onChange(newValue);
                  onBlur();
                }}
                mask="__:__"
                autoOk={autoOk}
                ampm={ampm}
                label={label}
                keyboardIcon={<AddAlarm fontSize="inherit" />}
                format={timeFormat}
                invalidLabel=""
                error={touched && !!error}
                helperText={(touched && (error || warning)) || helperText}
                InputProps={{ disableUnderline: false }}
                {...passedThroughProps}
              />
            </ThemeProvider>
          </MuiPickersUtilsProvider>
        )}
      </Grid>
    );
  }

  render() {
    const { readonly } = this.props;

    return readonly ? this.renderView() : this.renderEdit();
  }
}

class TimePicker extends React.PureComponent {
  render() {
    const { name, ...props } = this.props;

    return <Field name={name} component={RawTimePicker} fullWidth {...props} />;
  }
}

const RangeSliderWithTooltip = createSliderWithTooltip(Range);
@withOnMetaChange()
class RawRangeSlider extends React.PureComponent {
  render() {
    const {
      input,
      label,
      meta: { touched, error, warning },
      helperText = null,
      ...custom
    } = this.props;

    return (
      <FormControl component="fieldset" error={touched && !!error} {...custom}>
        <FormLabel component="legend">{label}</FormLabel>
        <RangeSliderWithTooltip
          min={0}
          max={100}
          /* allowCross={false} BUGGY: handles will be collapsed when one of them is adjusted */
          {...input}
        />
        <FormHelperText style={{ whiteSpace: 'pre' }}>{(touched && (error || warning)) || helperText}</FormHelperText>
      </FormControl>
    );
  }
}

class RangeSlider extends React.PureComponent {
  render() {
    const { name, ...props } = this.props;

    return <Field name={name} component={RawRangeSlider} fullWidth {...props} />;
  }
}

class RawSingle extends React.PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
  };

  state = {
    metas: R.pipe(
      R.filter(({ ignoreMeta }) => !ignoreMeta),
      R.pipe(
        R.map(({ name }) => [name, R.clone(defaultMeta)]),
        R.fromPairs,
      ),
    )(R.pathOr([], ['template', 'components'], this.props)),
  };

  render() {
    const { metas } = this.state;
    const {
      name: compositeName,
      index,
      removeSpace,
      template: { label = '', containerProps, wrapper: Wrapper, components, remove = {} },
      showBtnRemove = true,
      onRemove,
      disabled: singleDisabled,
      readonly: singleReadonly,
      onMetaChange: onSingleMetaChange,
      style,
      meta: { form },
      ...rest
    } = this.props;
    const globalProfileForm = form === 'globalProfile';

    return (
      <ReactHoverObserver>
        {({ isHovering }) => (
          <div>
            <Wrapper
              style={{ ...style, position: 'relative', marginRight: removeSpace }}
              isHovering={!singleDisabled && !singleReadonly && isHovering}
              meta={reduceMetas(metas)}
            >
              <Grid container {...containerProps}>
                <Grid item xs={12} style={{ marginBottom: '12px' }}>
                  <Typography variant="caption">
                    {label} {index + 1}
                  </Typography>
                </Grid>
                {components.map(({ name, component: Component, colSpans, injectWithIndex, ignoreMeta, ...props }) => (
                  <Grid key={name} item xs {...colSpans}>
                    <Component
                      name={`${compositeName}.${name}`}
                      disabled={!!singleDisabled}
                      readonly={!!singleReadonly}
                      {...props}
                      {...(injectWithIndex ? injectWithIndex(index)(rest) : {})}
                      /* NOTE: onMetaChange should be destructured above or put as last here
                        since this component can receive onMetaChange
                      */
                      {...(ignoreMeta
                        ? {}
                        : {
                            onMetaChange: (meta) =>
                              this.setState(({ metas: prevMetas }) => {
                                const nextMetas = update(prevMetas, { [name]: { $set: meta } });
                                const singleMeta = reduceMetas(nextMetas);
                                onSingleMetaChange && onSingleMetaChange(singleMeta);
                                return { metas: nextMetas };
                              }),
                          })}
                    />
                  </Grid>
                ))}
              </Grid>
              {!singleDisabled &&
                !singleReadonly &&
                !globalProfileForm &&
                showBtnRemove &&
                isHovering &&
                (({ icon: RemoveIcon = DeleteForever, injectWithIndex, style: removeStyle, ...removeProps }) => {
                  const { style: injectedStyle, ...injectedProps } = injectWithIndex
                    ? injectWithIndex(index)(rest)
                    : {};
                  return (
                    <div
                      onClick={onRemove}
                      style={{
                        width: '40px',
                        height: '40px',
                        position: 'absolute',
                        boxShadow: 'none',
                        top: '10%',
                        right: 10,
                        cursor: 'pointer',
                        ...removeStyle,
                        ...injectedStyle,
                      }}
                      {...removeProps}
                      {...injectedProps}
                    >
                      <RemoveIcon style={{ fontSize: 25, color: '#9E9E9E' }} />
                    </div>
                  );
                })(remove)}
            </Wrapper>
          </div>
        )}
      </ReactHoverObserver>
    );
  }
}

class RawMultiple extends React.PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
  };

  state = {
    /* NOTE: Do not use R.repeat or R.always,
      since both approaches will return array of same referenced elements
    */
    metas: R.times(() => R.clone(defaultMeta), R.pathOr(0, ['fields', 'length'], this.props)),
  };

  triggerMetaChange(metas) {
    const { onMetaChange: onMultipleMetaChange } = this.props;
    onMultipleMetaChange && onMultipleMetaChange(reduceMetas(metas));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const noOfSingles = this.props.fields.length;
    const nextNoOfSingles = nextProps.fields.length;

    // Added
    if (noOfSingles < nextNoOfSingles) {
      this.setState(({ metas: prevMetas }) => {
        const nextMetas = R.append(R.clone(defaultMeta), prevMetas);
        this.triggerMetaChange(nextMetas);
        return { metas: nextMetas };
      });
    }

    // Removed
    if (noOfSingles > nextNoOfSingles) {
      this.setState(({ metas: prevMetas }) => {
        const nextMetas = R.remove(this.removeIndex, 1, prevMetas);
        this.triggerMetaChange(nextMetas);
        return { metas: nextMetas };
      });
    }
  }

  render() {
    const {
      // NOTE: Take note that reduxForm also injects its own 'meta' via FieldArray
      fields,
      template,
      initialValues,
      disabled: multipleDisabled,
      readonly: multipleReadonly,
      wrapperMaxHeight = 'none',
      gutterBottom = 0,
      removeSpace = 20,
      onMetaChange: onMultipleMetaChange,
      ...rest
    } = this.props;

    // console.log('%cMultiple', 'font-size: 12px; color: #00b3b3', this.state, this.props);

    return (
      <Grid container>
        <Grid
          item
          xs={12}
          style={{
            maxHeight: wrapperMaxHeight + (multipleReadonly ? 20 : 0),
            overflowY: 'scroll',
            overflowX: 'hidden',
          }}
        >
          <ReactCSSTransitionGroup transitionName="multiple" transitionEnterTimeout={500} transitionLeaveTimeout={300}>
            {fields.map((field, index) => {
              const { id } = fields.get(index);
              /* NOTE: Do not use index as React element key. We encountered few expected problems:
                - Component is re-rendered & possibly losing its state though it doesn't need to
                - Meta change is triggered to the wrong component
              */
              return (
                <Grid key={id} container style={{ marginTop: index > 0 ? gutterBottom : 0 }}>
                  <Grid item xs={12}>
                    <RawSingle
                      name={field}
                      index={index}
                      removeSpace={removeSpace}
                      template={template}
                      showBtnRemove={fields.length > 1}
                      onRemove={() => {
                        this.removeIndex = index;
                        fields.remove(index);
                      }}
                      disabled={!!multipleDisabled}
                      readonly={!!multipleReadonly}
                      {...rest}
                      /* NOTE: onMetaChange should be destructured above or put as last here
                        since this component can receive onMetaChange
                      */
                      onMetaChange={(meta) =>
                        this.setState(({ metas: prevMetas }, { fields: prevFields }) => {
                          /* TRICKY: redux-form fires a series of
                            UNREGISTERED_FIELD after ARRAY_REMOVE
                            which causes onMetaChange to trigger on removed Single
                            --> Ignore if index doesn't exist.
                            Donot use fields from the outer scope
                            since it will retain the original length
                            (before removing item) due to closure
                          */
                          if (index > prevFields.length - 1) return {};
                          const nextMetas = update(prevMetas, { [index]: { $set: meta } });
                          this.triggerMetaChange(nextMetas);
                          return { metas: nextMetas };
                        })
                      }
                    />
                  </Grid>
                </Grid>
              );
            })}
          </ReactCSSTransitionGroup>
        </Grid>
        {(() => {
          const {
            add: { component: Component, colSpans, onClick, inject, style, ...props },
          } = template;
          const { onClick: injectedOnClick, ...injectedProps } = inject ? inject(rest) : {};

          return (
            !multipleReadonly && (
              <Grid item xs {...colSpans}>
                <Component
                  style={{
                    ...style,
                    width: R.when(
                      R.endsWith('%'),
                      (widthInPercentage) => `calc(${widthInPercentage} - ${removeSpace}px)`,
                    )(R.prop('width', style)),
                  }}
                  disabled={!!multipleDisabled}
                  readonly={!!multipleReadonly}
                  {...props}
                  {...injectedProps}
                  onClick={(e) => {
                    onClick && onClick(e);
                    injectedOnClick && injectedOnClick(e);
                    fields.push({ id: uuidv4(), ...(R.clone(initialValues) || {}) });
                  }}
                />
              </Grid>
            )
          );
        })()}
      </Grid>
    );
  }
}

class Multiple extends React.PureComponent {
  render() {
    const { name, ...props } = this.props;

    return <FieldArray name={name} component={RawMultiple} {...props} />;
  }
}

// TODO: Consider support dynamic changes to body.sections
@withStyles({
  resetContainer: {
    width: '100%',
    margin: 0,
  },
})
class RawView extends React.PureComponent {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string.isRequired,
    body: PropTypes.object.isRequired,
    active: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
    onMetaChange: PropTypes.func,
  };

  state = {
    metas: R.pipe(
      R.pathOr([], ['body', 'sections']),
      R.map(({ id: sectionId }) => [`section-${sectionId}`, R.clone(defaultMeta)]),
      R.fromPairs,
    )(this.props),
  };

  // shouldComponentUpdate(nextProps, nextState) {
  //   const diffState = shallowDiff(this.state, nextState);
  //   const diffProps = shallowDiff(this.props, nextProps);
  //   diffState && console.log('%cRawView - diffState', 'font-size: 12px; color: lightcoral', diffState, this.state, nextState);
  //   diffProps && console.log('%cRawView - diffProps', 'font-size: 12px; color: lightcoral', diffProps, this.props, nextProps);
  //   return !!diffState || !!diffProps;
  // }

  reportMetaChange = (metas) => {
    const { onMetaChange: onViewMetaChange } = this.props;
    const viewMeta = reduceMetas(metas);
    onViewMetaChange && onViewMetaChange(viewMeta);
  };

  changeMeta = memoize((name) => (meta) =>
    this.setState(({ metas: prevMetas }) => {
      const nextMetas = update(prevMetas, { [name]: { $set: meta } });
      this.reportMetaChange(nextMetas);
      return { metas: nextMetas };
    }),
  );

  render() {
    const {
      name,
      body: { containerProps = {}, sections = [] } = {},
      active,
      disabled: viewDisabled,
      classes,
      ...rest
    } = this.props;

    const passedThroughProps = R.omit(['id', 'onMetaChange', 'classes'], rest);

    return (
      <FormSection name={name}>
        <Grid container className={classes.resetContainer} justify="center" spacing={2} {...containerProps}>
          {R.addIndex(R.map)(({ id: sectionId, inject, style, ...props }, index) => (
            <Section
              key={sectionId}
              {...passedThroughProps}
              {...props}
              {...(inject ? inject(passedThroughProps) : {})}
              /* NOTE: Only active view should have all controls disabled
                  since the view control is disabled and won't be able to switch to other views.
                  We also overrides the disability by Section */
              disabled={!!viewDisabled && active}
              style={{ marginTop: index === 0 ? 0 : R.pathOr(0, ['marginTop'], style), ...style }}
              onMetaChange={this.changeMeta(`section-${sectionId}`)}
            />
          ))(sections)}
        </Grid>
      </FormSection>
    );
  }
}

// @withTheme()
class RawViews extends React.PureComponent {
  static propTypes = {
    views: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        name: PropTypes.string.isRequired,
        body: PropTypes.object.isRequired,
      }),
    ).isRequired,
    activeIndex: PropTypes.number.isRequired,
    onActiveIndexChange: PropTypes.func,
    // theme: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
    springConfig: PropTypes.object,
    onMetasChange: PropTypes.func,
  };

  state = {
    metas: R.pipe(
      R.pathOr([], ['views']),
      R.map(({ name: viewName }) => [viewName, R.clone(defaultMeta)]),
      R.fromPairs,
    )(this.props),
  };

  // shouldComponentUpdate(nextProps, nextState) {
  //   const diffState = shallowDiff(this.state, nextState);
  //   const diffProps = shallowDiff(this.props, nextProps);
  //   diffState && console.log('%cRawViews - diffState', 'font-size: 12px; color: lightcoral', diffState, this.state, nextState);
  //   diffProps && console.log('%cRawViews - diffProps', 'font-size: 12px; color: lightcoral', diffProps, this.props, nextProps);
  //   return !!diffState || !!diffProps;
  // }

  reportMetaChange = (metas) => {
    const { onMetasChange: onViewsMetasChange } = this.props;
    const viewsMetas = metas;
    onViewsMetasChange && onViewsMetasChange(viewsMetas);
  };

  changeMeta = memoize((name) => (meta) =>
    this.setState(({ metas: prevMetas }) => {
      const nextMetas = update(prevMetas, { [name]: { $set: meta } });
      this.reportMetaChange(nextMetas);
      return { metas: nextMetas };
    }),
  );

  UNSAFE_componentWillReceiveProps({ views: nextViews }) {
    nextViews !== this.props.views &&
      ((views) => {
        const { metas } = this.state;
        const nextMetas = {};
        R.forEach(({ name: viewName }) => {
          nextMetas[viewName] = metas[viewName] || R.clone(defaultMeta);
        })(views);
        this.reportMetaChange(nextMetas);
        this.setState({ metas: nextMetas });
      })(nextViews);
  }

  render() {
    const {
      views,
      activeIndex,
      onActiveIndexChange,
      theme = {},
      springConfig,
      animateHeight = false,
      ...rest
    } = this.props;

    // console.log('%crender RawViews', 'font-size: 12px; color: #00b3b3', this.state, this.props);
    const passedThroughProps = R.omit(['onMetasChange', 'classes'], rest);

    return (
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeIndex}
        onChangeIndex={onActiveIndexChange}
        disabled={R.pathOr(false, ['disabled'], rest)} // NOTE: For mobile
        springConfig={{
          duration: '1s',
          easeFunction: 'cubic-bezier(0.15, 0.3, 0.25, 1)',
          delay: '0s',
          ...springConfig,
        }}
        animateHeight={animateHeight}
      >
        {R.addIndex(R.map)(({ inject, ...props }, index) => (
          <RawView
            key={R.path(['id'], props) || R.path(['name'], props)}
            {...passedThroughProps}
            {...props}
            {...(inject ? inject(rest) : {})}
            active={index === activeIndex}
            onMetaChange={this.changeMeta(R.path(['name'], props))}
          />
        ))(views)}
      </SwipeableViews>
    );
  }
}

@withStyles({
  tabs: {
    minHeight: 0,
  },
  tab: {
    maxWidth: 'none',
    height: 'auto',
    minWidth: '225px',
    padding: '6px 22px 6px 10px',
  },
  subHeading: {
    top: 8,
    right: '-12px',
  },
  radio: {
    '&$checked': {
      color: '#FF5722',
    },
  },
  checked: {},
})
class RawBars extends React.PureComponent {
  static propTypes = {
    bars: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        name: PropTypes.string.isRequired,
        header: PropTypes.object.isRequired,
      }),
    ).isRequired,
    ignoreMetas: PropTypes.arrayOf(PropTypes.string),
    metas: PropTypes.object.isRequired,
    activeIndex: PropTypes.number.isRequired,
    onActiveIndexChange: PropTypes.func,
    appBarProps: PropTypes.object,
    tabsProps: PropTypes.object,
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
    classes: PropTypes.object,
  };

  handleChange = (event) => {
    this.props.handleSelectCategory(event.target.value);
    sessionStorage.setItem('selectedCategory', event.target.value);
  };

  render() {
    const {
      // bars,
      activeIndex,
      onActiveIndexChange,
      appBarProps,
      tabsProps,
      disabled: barsDisabled,
      readonly: barsReadonly,
      filterBars = false,
      filterValue = null,
      classes,
      isRadioStyle,
      radioControlValue,
      ...rest
    } = this.props;
    let { bars } = this.props;
    if (filterBars) {
      bars = bars.filter((f) => f.value === filterValue);
    }

    // console.log('%crender Tabs Bar', 'font-size: 12px; color: #00b3b3', this.props);

    const passedThroughProps = R.omit(['classes'], rest);

    if (isRadioStyle) {
      return (
        <AppBar position="static" {...appBarProps}>
          <MuiRadioGroup value={radioControlValue} onChange={this.handleChange}>
            <MuiTabs
              className={classes.tabs}
              value={activeIndex}
              onChange={(event, value) => onActiveIndexChange(value)}
              variant={(barsReadonly || tabsProps.fullWidth) && bars.length <= 3 ? 'fullWidth' : 'scrollable'}
              scrollButtons="auto"
              TabIndicatorProps={{
                style: {
                  backgroundColor: '#FF5722',
                },
              }}
              {...tabsProps}
            >
              {R.map(
                ({
                  id: tabId,
                  name: tabName,
                  header: { components: headerComponents = [], containerProps: headerContainerProps },
                }) => (
                  <MuiTab
                    key={tabId || tabName}
                    className={classes.tab}
                    disabled={!!barsDisabled}
                    label={
                      <FormSection name={tabName}>
                        <Grid className={classes.subHeading}>
                          <Grid container justify="center" alignItems="center" spacing={0} {...headerContainerProps}>
                            {headerComponents.map(
                              ({
                                id: headerKey,
                                component: Component,
                                colSpans: headerColSpans,
                                inject,
                                onClick,
                                ...props
                              }) => {
                                const { onClick: injectedOnClick, ...injectedProps } = inject
                                  ? inject(passedThroughProps)
                                  : {};

                                return (
                                  <Grid key={headerKey} item {...headerColSpans}>
                                    <Component
                                      // control={<Radio />}
                                      disabled={!!barsDisabled}
                                      readonly={!!barsReadonly}
                                      {...props}
                                      {...injectedProps}
                                      onClick={(e) => {
                                        onClick && onClick(e);
                                        injectedOnClick && injectedOnClick(e);
                                      }}
                                    />
                                    {/* <FormControlLabel value={tabName} control={<Radio />} label={tabName} /> */}
                                  </Grid>
                                );
                              },
                            )}
                          </Grid>
                        </Grid>
                      </FormSection>
                    }
                  />
                ),
              )(bars)}
            </MuiTabs>
          </MuiRadioGroup>
        </AppBar>
      );
    }

    return (
      <AppBar position="static" {...appBarProps}>
        <MuiTabs
          className={classes.tabs}
          value={activeIndex}
          onChange={(event, value) => onActiveIndexChange(value)}
          variant={(barsReadonly || tabsProps.fullWidth) && bars.length <= 3 ? 'fullWidth' : 'scrollable'}
          scrollButtons="auto"
          TabIndicatorProps={{
            style: {
              backgroundColor: '#FF5722',
            },
          }}
          {...tabsProps}
        >
          {R.map(
            ({
              id: tabId,
              name: tabName,
              header: { components: headerComponents = [], containerProps: headerContainerProps },
            }) => (
              <MuiTab
                key={tabId || tabName}
                className={classes.tab}
                disabled={!!barsDisabled}
                label={
                  <FormSection name={tabName}>
                    <Grid className={classes.subHeading}>
                      <Grid container justify="center" alignItems="center" spacing={0} {...headerContainerProps}>
                        {headerComponents.map(
                          ({
                            id: headerKey,
                            component: Component,
                            colSpans: headerColSpans,
                            inject,
                            onClick,
                            ...props
                          }) => {
                            const { onClick: injectedOnClick, ...injectedProps } = inject
                              ? inject(passedThroughProps)
                              : {};

                            return (
                              <Grid key={headerKey} item {...headerColSpans}>
                                <Component
                                  disabled={!!barsDisabled}
                                  readonly={!!barsReadonly}
                                  {...props}
                                  {...injectedProps}
                                  onClick={(e) => {
                                    onClick && onClick(e);
                                    injectedOnClick && injectedOnClick(e);
                                  }}
                                />
                              </Grid>
                            );
                          },
                        )}
                      </Grid>
                    </Grid>
                  </FormSection>
                }
              />
            ),
          )(bars)}
        </MuiTabs>
      </AppBar>
    );
  }
}

class RawTabs extends React.PureComponent {
  static propTypes = {
    tabs: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        name: PropTypes.string.isRequired,
        header: PropTypes.object.isRequired,
        body: PropTypes.object.isRequired,
      }),
    ).isRequired,
    ignoreMetas: PropTypes.arrayOf(PropTypes.string),
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
  };

  state = {
    activeIndex: this.props.radioControlValue === 'advanced' ? 1 : 0,
    metas: R.pipe(
      R.pathOr([], ['tabs']),
      R.map(({ name: tabName }) => [tabName, R.clone(defaultMeta)]),
      R.fromPairs,
    )(this.props),
  };

  componentDidMount() {
    if (this.props.ignoreMetas) {
      const activeIndex =
        R.findIndex(
          R.propEq(
            'name',
            R.pipe(R.map(R.prop('name')), R.difference(R.__, this.props.ignoreMetas))(this.props.tabs)[0],
          ),
        )(this.props.tabs) === -1
          ? 0
          : R.findIndex(
              R.propEq(
                'name',
                R.pipe(R.map(R.prop('name')), R.difference(R.__, this.props.ignoreMetas))(this.props.tabs)[0],
              ),
            )(this.props.tabs);
      this.setState({ activeIndex });
    }
  }
  changeActiveIndex = (activeIndex) => this.setState({ activeIndex });

  reportMetaChange = (metas, ignoreMetas = []) => {
    const { onMetaChange: onTabsMetaChange } = this.props;
    const tabsMeta = R.pipe(R.omit(ignoreMetas), reduceMetas)(metas);
    onTabsMetaChange && onTabsMetaChange(tabsMeta);
  };

  changeMetas = (metas) =>
    this.setState((_, { ignoreMetas = [] }) => {
      const nextMetas = metas;
      this.reportMetaChange(nextMetas, ignoreMetas);
      return { metas: nextMetas };
    });

  UNSAFE_componentWillReceiveProps({ tabs: nextTabs = [], ignoreMetas: nextIgnoreMetas = [] }) {
    const { tabs, ignoreMetas } = this.props;
    tabs !== nextTabs &&
      (() => {
        const { activeIndex } = this.state;
        const nextActiveIndex = R.pipe(
          R.path([activeIndex, 'name']),
          findIndexBy('name', R.__, nextTabs),
          R.when(R.identical(-1), R.always(0)),
        )(tabs);
        this.setState({ activeIndex: nextActiveIndex });
      })();

    !R.equals(ignoreMetas, nextIgnoreMetas) && this.reportMetaChange(this.state.metas, nextIgnoreMetas);
  }

  render() {
    const { activeIndex, metas } = this.state;
    const {
      tabs,
      ignoreMetas,
      appBarProps,
      tabsProps,
      springConfig,
      disabled,
      readonly,
      isRadioStyle = false,
      radioControlValue = '',
      handleSelectCategory = () => {},
      ...rest
    } = this.props;

    // console.log('%crender RawTabs', 'font-size: 12px; color: #00b3b3', this.state, this.props);
    const passedThroughProps = R.omit(['onMetaChange'], rest);

    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <RawBars
            bars={tabs}
            metas={metas}
            ignoreMetas={ignoreMetas}
            activeIndex={activeIndex}
            onActiveIndexChange={this.changeActiveIndex}
            appBarProps={appBarProps}
            tabsProps={tabsProps}
            disabled={!!disabled}
            readonly={!!readonly}
            isRadioStyle={isRadioStyle}
            radioControlValue={radioControlValue}
            handleSelectCategory={handleSelectCategory}
          />
        </Grid>
        <Grid item xs={12}>
          <RawViews
            views={tabs}
            activeIndex={activeIndex}
            onActiveIndexChange={this.changeActiveIndex}
            disabled={!!disabled}
            readonly={!!readonly}
            springConfig={springConfig}
            onMetasChange={this.changeMetas}
            {...passedThroughProps}
          />
        </Grid>
      </Grid>
    );
  }
}

class Tabs extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      fontRobotoReady: false,
    };

    const fontRoboto = new FontFaceObserver('Roboto');
    // // default is 3s timeout
    fontRoboto.load().then(
      // Successfully loaded
      () => console.log('Font Roboto has loaded'),
    );
  }

  componentDidMount() {
    this.setState({ fontRobotoReady: true });
  }

  render() {
    const { fontRobotoReady } = this.state;
    const { name, ...props } = this.props;

    return fontRobotoReady ? (
      <FormSection name={name}>
        <RawTabs {...props} />
      </FormSection>
    ) : (
      <Loading fullScreenOverlay={false} />
    );
  }
}

class RawSteps extends React.PureComponent {
  static propTypes = {
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        name: PropTypes.string.isRequired,
        label: PropTypes.string,
      }),
    ).isRequired,
    metas: PropTypes.object.isRequired,
    ignoreMetas: PropTypes.arrayOf(PropTypes.string),
    activeIndex: PropTypes.number.isRequired,
    onActiveIndexChange: PropTypes.func,
    stepperProps: PropTypes.object,
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
  };

  render() {
    const {
      steps,
      metas,
      ignoreMetas = [],
      activeIndex,
      onActiveIndexChange,
      stepperProps,
      disabled: stepsDisabled,
    } = this.props;

    // console.log('%crender RawSteps', 'font-size: 12px; color: #00b3b3', this.state, this.props);

    return (
      <MuiStepper {...stepperProps} nonLinear alternativeLabel activeStep={activeIndex}>
        {R.addIndex(R.map)(({ id, name, label }, index) => (
          <Step key={id || name} disabled={!!stepsDisabled}>
            <StepButton
              icon={
                <StatusIndicator
                  active={index === activeIndex && !stepsDisabled}
                  status={R.pipe(
                    () => (ignoreMetas.includes(name) ? R.clone(defaultMeta) : metas[name]),
                    decideStatusFromMeta,
                  )(metas)}
                  pristineIcon={Favorite}
                />
              }
              onClick={() => onActiveIndexChange(index)}
            >
              {label || name}
            </StepButton>
          </Step>
        ))(steps)}
      </MuiStepper>
    );
  }
}

@withStyles((theme) => ({
  badge: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  label: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },
}))
class RawSteppers extends React.PureComponent {
  static propTypes = {
    label: PropTypes.string,
    statusOnLabel: PropTypes.bool,
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        body: PropTypes.object.isRequired,
      }),
    ).isRequired,
    ignoreMetas: PropTypes.arrayOf(PropTypes.string),
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
  };

  state = {
    activeIndex: 0,
    metas: R.pipe(
      R.pathOr([], ['steps']),
      R.map(({ name: stepName }) => [stepName, R.clone(defaultMeta)]),
      R.fromPairs,
    )(this.props),
  };

  changeActiveIndex = (activeIndex) => this.setState({ activeIndex });

  reportMetaChange = (metas, ignoreMetas = []) => {
    const { onMetaChange: onSteppersMetaChange } = this.props;
    const steppersMeta = R.pipe(R.omit(ignoreMetas), reduceMetas)(metas);
    onSteppersMetaChange && onSteppersMetaChange(steppersMeta);
  };

  changeMetas = (metas) =>
    this.setState((_, { ignoreMetas = [] }) => {
      const nextMetas = metas;
      this.reportMetaChange(nextMetas, ignoreMetas);
      return { metas: nextMetas };
    });

  UNSAFE_componentWillReceiveProps({ ignoreMetas }) {
    !R.equals(ignoreMetas, this.props.ignoreMetas) && this.reportMetaChange(this.state.metas, ignoreMetas);
  }

  render() {
    const { activeIndex, metas } = this.state;
    const {
      label,
      statusOnLabel = true,
      steps,
      ignoreMetas,
      springConfig,
      disabled,
      readonly,
      stepperProps,
      classes,
      ...rest
    } = this.props;

    // console.log('%crender RawSteppers', 'font-size: 12px; color: #00b3b3', this.state, this.props);
    const passedThroughProps = R.omit(['onMetaChange'], rest);

    return (
      <Grid container spacing={0} justify="center">
        {label && (
          <Grid item xs={12}>
            <Badge
              className={classes.badge}
              badgeContent={
                <StatusIndicator status={R.pipe(reduceMetas, decideStatusFromMeta)(metas)} visible={statusOnLabel} />
              }
            >
              <Typography variant="body1" className={classes.label}>
                {label}
              </Typography>
            </Badge>
          </Grid>
        )}
        <Grid item xs={12}>
          <RawViews
            views={steps}
            activeIndex={activeIndex}
            onActiveIndexChange={this.changeActiveIndex}
            disabled={!!disabled}
            readonly={!!readonly}
            springConfig={springConfig}
            onMetasChange={this.changeMetas}
            {...passedThroughProps}
          />
        </Grid>
        <Grid item xs={8}>
          <RawSteps
            steps={steps}
            metas={metas}
            ignoreMetas={ignoreMetas}
            activeIndex={activeIndex}
            onActiveIndexChange={this.changeActiveIndex}
            stepperProps={stepperProps}
            disabled={!!disabled}
            readonly={!!readonly}
          />
        </Grid>
      </Grid>
    );
  }
}

class Steppers extends React.PureComponent {
  render() {
    const { name, ...props } = this.props;

    return (
      <FormSection name={name}>
        <RawSteppers {...props} />
      </FormSection>
    );
  }
}

@withStyles((theme) => ({
  avatarButton: {
    width: 90,
    height: 90,
  },
  avatarPreview: {
    margin: '0 auto',
    width: 350,
    height: 354, //Canvas drawn by AvatarEditor adds 4px. This prevents flickering on redraw.
  },
  loadedAvatarPreview: {
    margin: '0 auto',
    width: 350,
    height: 354,
    boxShadow: theme.shadows[5],
  },
  avatarEditorPanel: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: theme.spacing(90),
    borderRadius: 10,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: 'none',
  },
  avatarEditor: {
    backgroundColor: 'white',
  },
  avatarEditorEmpty: {
    width: 350,
    height: 354, // The canvas drawn by AvatarEditor will add extra 4px. This is to prevent flickering effect
    margin: '0 auto',
    position: 'relative',
  },
  center: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}))
class RawAvatar extends React.PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
  };

  initState = {
    dirty: false, // True if new image uploaded, or editing done on image preview
    image: '',
    preview: '',
    angle: 0,
    scale: 1,
  };

  state = {
    ...this.initState,
    edit: false,
  };

  imgDataHeader = 'data:image/jpeg;base64,';

  labelType = this.getLabelType();

  setPreview = () => {
    const imgCanvas = this.avatarEditor.getImageScaledToCanvas();
    const context = imgCanvas.getContext('2d');
    context.globalCompositeOperation = 'destination-over'; // Set to draw behind current content
    context.fillStyle = '#FFFFFF'; // Set background color
    context.fillRect(0, 0, imgCanvas.width, imgCanvas.height); // draw background on entire canvas
    const convertedCanvas = document.createElement('canvas');
    const convertedCtx = convertedCanvas.getContext('2d');
    convertedCanvas.width = imgCanvas.width;
    convertedCanvas.height = imgCanvas.height;
    convertedCtx.drawImage(imgCanvas, 0, 0);
    this.setState({
      preview: convertedCanvas.toDataURL('image/jpeg', 0.5),
    });
  };
  debouncedSetPreview = debounce(this.setPreview, 200);

  renderAvatarEditor(imageData, angle, scale) {
    if (typeof imageData === 'string' || typeof imageData === 'object') {
      return (
        <Grid item>
          <AvatarEditor
            className={this.props.classes.avatarEditor}
            ref={(node) => {
              this.avatarEditor = node;
            }}
            width={300}
            height={300}
            borderRadius={150}
            rotate={angle}
            color={[235, 235, 235, 0.6]}
            scale={scale}
            image={imageData}
            onPositionChange={this.debouncedSetPreview}
            onImageChange={() => this.setState({ dirty: true })}
            onImageReady={this.setPreview}
          />
        </Grid>
      );
    }
    return (
      // Error handling + catch-all
      <ImageAvatar
        className={this.props.classes.avatarPreview}
        gothumb={false}
        alt={<Error style={{ fontSize: 50 }} color="secondary" />}
      />
    );
  }

  renderSelectImageText() {
    return (
      <Grid item>
        <ImageAvatar
          className={this.props.classes.avatarPreview}
          gothumb={false}
          alt={<InsertPhoto style={{ fontSize: 100 }} />}
        />
      </Grid>
    );
  }

  renderEditor() {
    const { image, angle, scale } = this.state;
    const { classes } = this.props;
    if (!image) return this.renderSelectImageText();
    // Image type will stored in string format after user clicks "Save Photo" on image selection
    // or if image is loaded from server as existing profile image
    if (typeof image === 'string') {
      // Header will be data:image/jpeg after user saves photo but not uploaded or committed to server yet
      if (image.substring(0, this.imgDataHeader.length) === this.imgDataHeader) {
        return this.renderAvatarEditor(image, angle, scale);
      }
      // Image is loaded from the server as a url
      return (
        <Grid item xs={12}>
          <ImageAvatar className={classes.loadedAvatarPreview} gothumb={true} src={image} variant="circle" />
        </Grid>
      );
    }
    // If image is loaded from user's local drive it is in "object" format
    return this.renderAvatarEditor(image, angle, scale);
  }

  /**
   * Toolbar below the avatar editor to rotate and zoom in/out
   * The user can only see the toolbar buttons to edit image when:
   * 1. The image preview is blank - this lets the user know that the controls are available once an image is uploaded
   * 2. After a new image is uploaded from file
   * 3. After a new image is uploaded from file and change is saved locally but not down to server yet
   */
  renderTools() {
    const { image, angle, scale, preview } = this.state;
    let isVisible = 'hidden';
    if (
      !image ||
      (typeof image === 'string' && image.substring(0, this.imgDataHeader.length) === this.imgDataHeader) ||
      typeof image === 'object'
    ) {
      isVisible = 'visible'; // Always render the toolbar but toggle visibility to user to prevent alignment changes / flickering
    }
    return (
      <Grid
        container
        direction="row"
        spacing={0}
        style={{
          visibility: isVisible,
          paddingBottom: 0,
          marginBottom: 0,
        }}
      >
        <Grid item xs={12}>
          <Grid container justify="center" spacing={40}>
            <Grid item>
              <IconButton disabled={!preview} onClick={() => this.setState({ angle: angle - 90 }, this.setPreview)}>
                <RotateLeft style={{ fontSize: 30 }} />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton disabled={!preview} onClick={() => this.setState({ angle: angle + 90 }, this.setPreview)}>
                <RotateRight style={{ fontSize: 30 }} />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                disabled={!preview || scale >= 2}
                onClick={() => this.setState({ scale: scale + 0.1 }, this.setPreview)}
              >
                <ZoomIn style={{ fontSize: 30 }} />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                disabled={!preview || scale < 0.2}
                onClick={() => this.setState({ scale: scale - 0.1 }, this.setPreview)}
              >
                <ZoomOut style={{ fontSize: 30 }} />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  manageUploadImage = (e) => {
    if (e.target.files[0]) {
      this.setState({
        ...this.initState,
        image: e.target.files[0],
        dirty: true,
      });
    }
  };

  // Renders the "Delete", "Upload", "Save" buttons
  renderControlButtons() {
    const {
      input: { onChange },
    } = this.props;
    return (
      <Grid container spacing={0} style={{ marginTop: 20 }}>
        <Grid item xs>
          <Button
            className="milo-btn-white"
            variant="contained"
            component="button"
            disabled={!this.state.image}
            style={{ width: 150, padding: 0 }}
            onClick={() => this.setState({ ...this.initState, dirty: true })}
            startIcon={<Delete />}
          >
            Remove {this.labelType}
          </Button>
        </Grid>
        <Grid item xs>
          <input
            accept=".png, .jpg, .jpeg, .svg"
            id="icon-button-file"
            type="file"
            style={{ display: 'none' }}
            onClick={(evt) => {
              evt.target.value = null;
            }} // eslint-disable-line no-param-reassign
            onChange={(evt) => {
              this.manageUploadImage(evt);
            }}
          />
          <label htmlFor="icon-button-file">
            <Button
              className="milo-btn-white"
              variant="contained"
              component="span"
              style={{ width: 150, padding: 0 }}
              startIcon={<AddPhotoAlternate />}
            >
              Upload {this.labelType}
            </Button>
          </label>
        </Grid>
        <Grid item xs={4} />
        <Grid item xs>
          <Button
            variant="contained"
            component="button"
            style={{ width: 150, padding: 0 }}
            disabled={!this.state.dirty}
            className="milo-btn-orange"
            onClick={() => this.setState({ dirty: false, edit: false }, onChange(this.state.preview))}
            startIcon={<Save />}
          >
            Save {this.labelType}
          </Button>
        </Grid>
      </Grid>
    );
  }

  /**
   * Returns "Logo" or "Photo" for label based on whether it's individual or group / org entity
   * "Logo" applies to non-individual / group / org entities
   * "Photo" applies to  individual / human entities
   * Current forms that apply to orgs:
   * 1.createGroupOrg
   * 2.createEntityOrg
   * Current forms that apply to individuals:
   * 1.globalProfile
   * 2.provider
   */
  getLabelType() {
    let labelType = 'Photo';
    if (this.props.meta.form.includes('Org')) labelType = 'Logo';
    return labelType;
  }

  renderTooltip() {
    const {
      input: { value },
      meta: { touched, error },
      emptyContent = <InsertPhoto style={{ fontSize: 50 }} />,
      errorContent = <Error style={{ fontSize: 50 }} color="secondary" />,
      disabled,
      readonly,
      classes,
    } = this.props;
    let tLabel = `Edit ${this.labelType}`;
    if (!value) tLabel = `Add ${this.labelType}`;
    const isDisabled = !!disabled || !!readonly;
    return (
      <Tooltip title={touched && error ? error : tLabel}>
        <span style={isDisabled ? { pointerEvents: 'none' } : {}}>
          <Fab
            style={{ width: 'auto', height: 'auto' }}
            onClick={() => this.setState({ edit: true })}
            disabled={isDisabled}
          >
            <ImageAvatar
              className={classes.avatarButton}
              gothumb={true}
              src={value}
              alt={touched && error ? errorContent : emptyContent}
            />
          </Fab>
        </span>
      </Tooltip>
    );
  }

  renderHeader() {
    return (
      <Typography align="center" style={{ fontSize: 25, fontWeight: 'bold' }}>
        {this.props.input.value ? `Edit ${this.labelType}` : `Add ${this.labelType}`}
      </Typography>
    );
  }

  renderCriteriaText() {
    return (
      <Typography align="center" style={{ fontSize: 13, lineHeight: 1.5 }}>
        File types: jpg, png, svg <br />
      </Typography>
    );
  }

  render() {
    const { edit } = this.state;
    const {
      input: { value },
      classes,
    } = this.props;
    return (
      <React.Fragment>
        {this.renderTooltip()}
        <Modal open={edit} onRendered={() => this.setState({ image: value })}>
          <Paper className={classes.avatarEditorPanel}>
            <Grid container alignItems="center">
              <Grid item xs />
              <Grid item xs={6}>
                {this.renderHeader()}
              </Grid>
              <Grid item xs>
                <Grid container justify="flex-end">
                  <IconButton onClick={() => this.setState({ ...this.initState, edit: false })}>
                    <Close style={{ fontSize: 30 }} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={3} style={{ marginTop: 20 }} alignItems="center">
              <Grid item xs />
              <Grid item xs={6}>
                <Grid container direction="column" alignItems="center" spacing={1}>
                  <Grid item xs>
                    {this.renderEditor()}
                  </Grid>
                  <Grid item xs style={{ marginTop: 2, marginBottom: 2 }}>
                    {this.renderCriteriaText()}
                  </Grid>
                  <Grid item xs>
                    {this.renderTools()}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs />
            </Grid>
            {this.renderControlButtons()}
          </Paper>
        </Modal>
      </React.Fragment>
    );
  }
}

class Avatar extends React.PureComponent {
  render() {
    const { name, ...props } = this.props;

    return <Field name={name} component={RawAvatar} {...props} />;
  }
}

const withReduxForm = (objOrMapper) => (DecoratedComponent) => {
  const createReduxForm = memoize(
    ({ layout: { header = {}, body = {}, footer = {}, additional = {} } = {}, config = {} } = {}) => {
      class FormHeader extends React.PureComponent {
        static propTypes = {
          components: PropTypes.array,
          disabled: PropTypes.bool,
          readonly: PropTypes.bool,
        };

        static defaultProps = {
          components: null,
        };

        render() {
          const {
            components,
            itemProps,
            containerProps,
            divider,
            disabled: formHeaderDisabled,
            readonly: formHeaderReadonly,
            ...rest
          } = {
            ...this.props,
            ...header,
            ...(header.inject && header.inject(this.props)),
          };

          return (
            components && (
              <Grid item xs={12} {...itemProps}>
                <Grid container justify="space-between" alignItems="center" spacing={2} {...containerProps}>
                  {components.map(({ id, component: Component, colSpans, inject, ...props }) => (
                    <Grid key={id} item {...colSpans}>
                      <Component
                        disabled={!!formHeaderDisabled}
                        readonly={!!formHeaderReadonly}
                        {...props}
                        {...(inject ? inject(rest) : {})}
                      />
                    </Grid>
                  ))}
                  {divider && (
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )
          );
        }
      }

      @withStyles({
        resetContainer: {
          width: '100%',
          margin: 0,
        },
      })
      class FormBody extends React.PureComponent {
        static propTypes = {
          sections: PropTypes.array,
          wrapper: PropTypes.func,
          disabled: PropTypes.bool,
          readonly: PropTypes.bool,
        };

        static defaultProps = {
          wrapper: R.prop('children'),
        };

        render() {
          const { sections, wrapper: Wrapper, itemProps, containerProps, classes, bodyFooter, ...rest } = {
            ...this.props,
            ...body,
            ...additional,
            ...(body.inject && body.inject(this.props)),
          };

          return (
            <Grid item xs={12} {...itemProps}>
              <Wrapper>
                <Grid container justify="center" spacing={2} {...containerProps} className={classes.resetContainer}>
                  {sections.map(({ id, inject, additional, ...props }) => {
                    return (
                      <React.Fragment key={id}>
                        <Section {...rest} {...props} {...(inject ? inject(rest) : {})} />
                        {additional && additional.bodyFooter(rest)}
                      </React.Fragment>
                    );
                  })}
                </Grid>
              </Wrapper>
            </Grid>
          );
        }
      }

      class FormFooter extends React.PureComponent {
        static propTypes = {
          components: PropTypes.array,
          disabled: PropTypes.bool,
          hide: PropTypes.bool,
        };

        static defaultProps = {
          components: null,
        };

        render() {
          const {
            components,
            itemProps,
            containerProps,
            divider,
            disabled: formFooterDisabled,
            hide: formFooterHide,
            ...rest
          } = {
            ...this.props,
            ...footer,
            ...(footer.inject && footer.inject(this.props)),
          };

          if (formFooterHide) return null;

          return (
            components && (
              <Grid item xs={12} {...itemProps}>
                <Grid container justify="space-between" alignItems="center" spacing={2} {...containerProps}>
                  {divider && (
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  )}
                  {components.map(({ id, component: Component, colSpans, inject, ...props }) => (
                    <Grid key={id} item {...colSpans}>
                      <Component disabled={!!formFooterDisabled} {...props} {...(inject ? inject(rest) : {})} />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            )
          );
        }
      }

      class Form extends React.PureComponent {
        static propTypes = {
          autocomplete: PropTypes.string,
          containerProps: PropTypes.object,
          className: PropTypes.string,
          style: PropTypes.any,
        };

        static defaultProps = {
          autocomplete: 'on',
        };

        render() {
          const {
            /* redux-form */
            handleSubmit,
            /* direct */
            autocomplete,
            containerProps,
            className,
            style,
            ...rest
          } = this.props;

          return (
            <form
              noValidate
              style={config.style || {}}
              autoComplete={autocomplete}
              onSubmit={(e) => {
                /* NOTE: this to prevent triggering submission of child form
            from triggering submission of parent form too, if any.
            In React, event propagates along the React hierarchy, not DOM.
            child <form> is not part of parent <form> DOM hierarchy
            created by material-ui's Modal, which probably uses React Portal.
            Ref: https://github.com/erikras/redux-form/issues/3701
          */
                e.stopPropagation();
                handleSubmit(e);
              }}
            >
              <Grid container {...containerProps} className={className} style={style}>
                <FormHeader {...rest} disabled={rest.submitting} />
                <FormBody {...rest} disabled={rest.submitting} />
                <FormFooter {...rest} disabled={rest.submitting} />
              </Grid>
            </form>
          );
        }
      }

      return reduxForm(config)(Form);
    },
  );

  class WithReduxForm extends React.PureComponent {
    render() {
      const option = R.when(R.is(Function), (mapper) => mapper(this.props))(objOrMapper);
      return <DecoratedComponent {...this.props} Form={createReduxForm(option)} />;
    }
  }

  return WithReduxForm;
};

@withStyles((theme) => ({
  panel: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '80vw',
    maxHeight: '80vh',
    overflow: 'scroll',
    borderRadius: 10,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
  },
}))
@withReduxForm(R.pick(['layout', 'config']))
class RawModalSubFormPanel extends React.PureComponent {
  static propTypes = {
    Form: PropTypes.func.isRequired,
    initialValues: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    cancel: PropTypes.func.isRequired,
    save: PropTypes.func.isRequired,
    readonly: PropTypes.bool,
    className: PropTypes.string,
    style: PropTypes.object,
    classes: PropTypes.object.isRequired,
  };

  static defaultProps = {
    readonly: false,
  };

  constructor(props) {
    super(props);

    this.form = createRef();
  }

  state = {
    openConfirmDialog: false,
    error: '',
    isConfirmed: false,
    formValues: null,
  };

  handleOpenConfirmDialog = (values) => {
    this.setState({ openConfirmDialog: true, error: 'POTENTIAL_HIGH_ALERTS_VOLUME', formValues: values });
  };

  handleCloseConfirmDialog = () => {
    this.setState({ openConfirmDialog: false, error: '', isConfirmed: false }, () => {
      this.form.current.reset();
    });
  };

  handleConfirm = () => {
    this.setState({ openConfirmDialog: false, error: '', isConfirmed: true });
  };

  submitForm = () => {
    const { save, additionalSubmit, selectedValue } = this.props;
    const { formValues: values } = this.state;

    this.setState({ openConfirmDialog: false, error: '', isConfirmed: true }, () => {
      new Promise((resolve) => {
        const selectedCategory = sessionStorage.getItem('selectedCategory') || selectedValue;
        if (selectedCategory === 'oneTime') {
          delete values.alertCategory.advanced.selected;
          values.alertCategory.oneTime.selected = true;
          resolve(values);
          save(values);
          additionalSubmit();
        } else if (selectedCategory === 'advanced') {
          delete values.alertCategory.oneTime.selected;
          values.alertCategory.advanced.selected = true;
          resolve(values);
          save(values);
          additionalSubmit();
        }
      });
    });
  };

  checkHasAlertConfig = (alertConfig) => {
    let hasAlert = false;

    for (const subType in alertConfig) {
      // Property is not inherited from prototype
      if (alertConfig.hasOwnProperty(subType)) {
        const subTypeConfig = alertConfig[subType];
        for (const alertInput in subTypeConfig) {
          if(subTypeConfig.hasOwnProperty(alertInput)) {
            const hasConfig = subTypeConfig[alertInput];
            for (const configKey in hasConfig) {
              if(hasConfig.hasOwnProperty(configKey)) {
                const keyValue = hasConfig[configKey];
                const sendVia = keyValue && keyValue.checkeds;
                if(sendVia) {
                  if(sendVia.email || sendVia.sms || sendVia.inApp) hasAlert = true;
                }
              }
            }
          }
        }
      }
    }

    return hasAlert;
  } 

  potentialHighAlertValidation = (minutes) => {
    if (Number(minutes) >= 1 && Number(minutes) < 5) {
      return true;
    }

    return false;
  };

  render() {
    const {
      Form,
      initialValues,
      open,
      cancel,
      save,
      readonly,
      containerProps,
      classes,
      className,
      style,
      runtimeProps,
      isOneTimeTask,
      hasRadioTab = false,
      additionalSubmit = () => {},
      selectedValue = '',
    } = this.props;
    const { openConfirmDialog, error, isConfirmed } = this.state;

    return (
      <React.Fragment>
        <Modal open={open}>
          <Paper className={classNames(classes.panel, className)} style={style}>
            <IconButton disableRipple={true} style={{ position: 'absolute', top: 10, right: 10 }} onClick={cancel}>
              <Close style={{ fontSize: 30 }} />
            </IconButton>
            <Form
              ref={this.form}
              readonly={readonly}
              initialValues={initialValues}
              runtimeProps={runtimeProps}
              containerProps={containerProps}
              onSubmit={(values) =>
                new Promise((resolve) => {
                  if (!hasRadioTab) {
                    resolve(values);
                    save(values);
                  } else {
                    const selectedCategory = sessionStorage.getItem('selectedCategory') || selectedValue;
                    console.log('>> selectedCategory: ', selectedCategory);

                    if (selectedCategory === 'oneTime') {
                      const oneTimeMissedHours = values.alertCategory.oneTime.alertConfig.missed.missed.hours.value;
                      const oneTimeMissedMinutes = values.alertCategory.oneTime.alertConfig.missed.missed.minutes.value;
                      const oneTimeCHMinutes =
                        values.alertCategory.oneTime.alertConfig.critical.criticallyHigh.minutes.value;
                      const oneTimeCLMinutes =
                        values.alertCategory.oneTime.alertConfig.critical.criticallyLow.minutes.value;
                      const oneTimeHMinutes = values.alertCategory.oneTime.alertConfig.warning.high.minutes.value;
                      const oneTimeLMinutes = values.alertCategory.oneTime.alertConfig.warning.low.minutes.value;

                      const validateMissed =
                        Number(oneTimeMissedHours) === 0 && this.potentialHighAlertValidation(oneTimeMissedMinutes);
                      const validateCH = this.potentialHighAlertValidation(oneTimeCHMinutes);
                      const validateCL = this.potentialHighAlertValidation(oneTimeCLMinutes);
                      const validateH = this.potentialHighAlertValidation(oneTimeHMinutes);
                      const validateL = this.potentialHighAlertValidation(oneTimeLMinutes);

                      const validatePotentialHighAlert =
                        validateMissed || validateCH || validateCL || validateH || validateL;

                      if (!isOneTimeTask && !isConfirmed && validatePotentialHighAlert) {
                        this.handleOpenConfirmDialog(values);
                      } else {
                        delete values.alertCategory.advanced.selected;

                        const oneTimeAlert = values.alertCategory.oneTime.alertConfig;

                        const hasAlertConfig = this.checkHasAlertConfig(oneTimeAlert);

                        if(hasAlertConfig) {
                          values.alertCategory.oneTime.selected = true;
                        }else{
                          delete values.alertCategory.oneTime.selected;
                        }
                        
                        resolve(values);
                        save(values);
                        additionalSubmit();
                      }
                    } else if (selectedCategory === 'advanced') {
                      const advancedMissedHours = values.alertCategory.advanced.alertConfig.missed.missed.hours.value;
                      const advancedMissedMinutes =
                        values.alertCategory.advanced.alertConfig.missed.missed.minutes.value;
                      const advancedCHMinutes =
                        values.alertCategory.advanced.alertConfig.critical.criticallyHigh.minutes.value;
                      const advancedCLMinutes =
                        values.alertCategory.advanced.alertConfig.critical.criticallyLow.minutes.value;
                      const advancedHMinutes = values.alertCategory.advanced.alertConfig.warning.high.minutes.value;
                      const advancedLMinutes = values.alertCategory.advanced.alertConfig.warning.low.minutes.value;

                      const validateMissed =
                        Number(advancedMissedHours) === 0 && this.potentialHighAlertValidation(advancedMissedMinutes);
                      const validateCH = this.potentialHighAlertValidation(advancedCHMinutes);
                      const validateCL = this.potentialHighAlertValidation(advancedCLMinutes);
                      const validateH = this.potentialHighAlertValidation(advancedHMinutes);
                      const validateL = this.potentialHighAlertValidation(advancedLMinutes);

                      const validatePotentialHighAlert =
                        validateMissed || validateCH || validateCL || validateH || validateL;

                      if (!isOneTimeTask && !isConfirmed && validatePotentialHighAlert) {
                        this.handleOpenConfirmDialog(values);
                      } else {
                        delete values.alertCategory.oneTime.selected;
                        values.alertCategory.advanced.selected = true;

                        resolve(values);
                        save(values);
                        additionalSubmit();
                      }
                    }
                  }
                })
              }
            />
          </Paper>
        </Modal>
        <DialogProgramValidation
          open={openConfirmDialog}
          error={error}
          toggleCloseDialog={this.handleCloseConfirmDialog}
          proceed={this.submitForm}
        />
      </React.Fragment>
    );
  }
}

@withOnMetaChange()
class RawModalSubForm extends React.PureComponent {
  static propTypes = {
    trigger: PropTypes.shape({
      component: PropTypes.func.isRequired,
    }).isRequired,
    layout: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    input: PropTypes.object.isRequired,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    disabled: false,
  };

  state = {
    edit: false,
  };

  render() {
    const { edit } = this.state;
    const {
      trigger: { component: Trigger, ...triggerProps },
      input: { value, onBlur, onFocus },
      disabled,
      ...rest
    } = this.props;

    // console.log('%cModalSubForm', 'font-size: 12px; color: #00b3b3', this.state, this.props);

    return (
      <React.Fragment>
        <Trigger
          {...rest}
          disabled={disabled}
          {...triggerProps}
          onClick={(e) => this.setState({ edit: true }, onFocus.bind(null, e))}
        />
        <RawModalSubFormPanel
          initialValues={value}
          open={edit}
          cancel={() => this.setState({ edit: false }, onBlur.bind(null, value))}
          save={(values) => this.setState({ edit: false }, onBlur.bind(null, values))}
          {...rest}
        />
      </React.Fragment>
    );
  }
}

class ModalSubForm extends React.PureComponent {
  render() {
    const { name, ...props } = this.props;

    return <Field name={name} component={RawModalSubForm} {...props} />;
  }
}

export {
  withReduxForm,
  withOnMetaChange,
  TextField,
  ShowHidePasswordTextField,
  Phone,
  RawSelect,
  Select,
  TypeSelect,
  Switch,
  RadioGroup,
  RadioboxLabel,
  CheckboxLabel,
  CheckboxGroup,
  Checkbox,
  DatePicker,
  TimePicker,
  RangeSlider,
  Multiple,
  Tabs,
  Steppers,
  Avatar,
  ModalSubForm,
  MultiSelect,
  CheckboxRightLabelGroup,
  RawTabs,
};
